@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
.loader-container {
  width: 100px;
  height: 100px;
  position: relative;
  margin: 20px;
}

.loader-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #3498db; /* Change the color as needed */
  color: #000;
  padding: 10px;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}

body {
  font-family: Inter !important;
}

:root {
  --aspect-ratio: 0.5625;
}

.aspectRatio {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.main {
  width: 100%;
  --size: calc(var(--aspect-ratio) * 89vw);
  height: calc(var(--size) - 16px);
  /* background-color: #0c4da9;
  padding: 0.5%; */
}

.leftCard,
.rightCard {
  height: calc(0.5625 * 81.57vw);
  height: calc(var(--aspect-ratio) * 81.57vw);
  /* height: 94vh; */
}

/* .leftCard {
  margin-right: 0.5%;
  border-radius: 5px;
}

.rightCard {
  margin-left: 0.5%;
  border-radius: 5px;
} */

/* .login-card {
  max-width: 350px;
  margin-left: 3vh !important;
  text-align: left !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 20px !important;
  border: 1px solid #ebeded !important;
} */

.topay-aspect-ratio {
  max-width: 400px !important;
}

/* .add-payment-btn {
  height: 8vh !important;
  width: 100% !important;
  background: #20b883 !important;
  color: #ffffff !important;
  border-radius: 6px !important;
  opacity: 1;
  font-weight: bold !important;
  font-size: 1.5vw;
} */

/* Print Customer and Order Details Modal */

.customerPrintDetails {
  padding: 10px;
  margin: 3px;
  font-weight: 600;
  border: 1px solid #000000;
  border-style: dashed;
}

.printPaymentBtn,
.cancelPaymentBtn {
  color: #ffffff;
  width: 30%;
  border-radius: 5px;
  background: #607d8b;
}

.cancelPaymentBtn:hover,
.printPaymentBtn:hover,
.cancelPaymentBtn:focus,
.printPaymentBtn:focus {
  border-radius: 5px;
  background: #607d8b;
  border-color: #607d8b;
  color: #ffffff;
}

.paymtName,
.paymtAmt {
  font-size: 1vw;
}

/* .delete-icon {
  width: 12px !important;
  margin-top: -3px !important;
} */

/* .paymt-card {
  padding: 15px;
  border: 1px solid #ebeded !important;
  border-radius: 6px !important;
  opacity: 1 !important;
} */

.text-align-center {
  text-align: center !important;
}

/* .topay-input {
  box-shadow: 0px 0.5px 4px #00000024 !important;
  border: 1px solid #ebeded !important;
  border-radius: 5px !important;
  height: 8vh !important;
  width: 100%;
  font-size: 1.8vw;
  font-weight: 600;
  text-align: center;
} */

.toPayText {
  font-size: 1.5vw;
  font-weight: 600;
  color: #000000;
}

.toPayTextValue {
  float: right;
  font-weight: 600;
  color: #000000;
  font-size: 1.5vw;
}

.para-margin {
  margin-bottom: 8px !important;
}

.amt-dial-btn {
  width: 8.6vw;
  height: 11.5vh;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: none;
  font-size: 2vw;
  font-weight: 600;
  color: #0f0718;
}

.amt-dial-btn-market {
  width: 100%;
  height: 6.8vh;
  border-radius: 4px;
  background: #92909880;
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: none;
  font-size: 1.25em;
  font-weight: 500;
  color: #0f0718;
  cursor: pointer;
}
.amt-dial-btn-market-qty {
  width: 49%;
  height: 7vh;
  border-radius: 4px;
  background: #92909880;
  /* box-shadow: 0px 3px 6px #00000026; */
  border: none;
  font-size: 1.25em;
  font-weight: 500;
  color: #0f0718;
  cursor: pointer;
}

.amt-dial-btn {
  width: 100%;
  height: 10vh;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 3px 6px #00000026;
  border: none;
  font-size: 1.2vw;
  font-weight: 600;
  color: #0f0718;
  cursor: pointer;
}

.amt-dial-btn2 {
  width: 100%;
  height: 21vh;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 3px 6px #00000026;
  border: none;
  font-size: 1.2vw;
  font-weight: 600;
  color: #0f0718;
  cursor: pointer;
}

.amt-dial-btn-market-return {
  width: 100%;
  height: 9vh;
  border-radius: 4px;
  background: #92909880;
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: none;
  font-size: 1.2vw;
  font-weight: 600;
  color: #0f0718;
  cursor: pointer;
}

.amt-dial-btn-super-market {
  width: 8vw;
  height: 9.4vh;
  border-radius: 5px;
  background: #92909880;
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: none;
  font-size: 1.5em;
  font-weight: 500;
  color: #0f0718;
}

.amt-dial-btn-super-market-cancel-discount {
  width: 8vw;
  height: 9.5vh;
  border-radius: 5px;
  background: #92909880;
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: none;
  font-size: 1.5em;
  font-weight: 500;
  color: #0f0718;
}

.amt-dial-btn-market2 {
  width: 100%;
  height: 14.6vh;
  border-radius: 4px;
  background: #92909880;
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: none;
  font-size: 1.25em;
  font-weight: 500;
  color: #0f0718;
  cursor: pointer;
}

.amt-dial-btn-market2-return {
  width: 100%;
  height: 19vh;
  border-radius: 4px;
  background: #92909880;
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: none;
  font-size: 1.2vw;
  font-weight: 600;
  color: #0f0718;
  cursor: pointer;
}

.amt-dial-btn-market-discount {
  width: 8vw;
  height: 19.9vh;
  border-radius: 5px;
  background: #92909880;
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: none;
  font-size: 1.5em;
  font-weight: 500;
  color: #0f0718;
  cursor: pointer;
}

.amt-dial-disc-btn {
  width: 5.6vw;
  height: 10vh;
  border-radius: 10px;
  background: #f3f4f9;
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: none;
  font-size: 1.9vw;
  font-weight: 600;
}

.amt-dial-btn-back {
  margin-top: -5px;
}

.amt-dial-btn-production {
  width: 100%;
  height: 10vh;
  border-radius: 4px;
  background: #92909880;
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: none;
  font-size: 1.25em;
  font-weight: 500;
  color: #0f0718;
  cursor: pointer;
}

.amt-dial-btn-production-ent {
  width: 100%;
  height: 21.1vh;
  border-radius: 4px;
  background: #92909880;
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: none;
  font-size: 1.25em;
  font-weight: 500;
  color: #0f0718;
  cursor: pointer;
}

/* .topay-entr-amt {
  margin-bottom: 0;
  color: #030d1c;
  font-size: 1.5vw;
  font-weight: 600;
} */

.right-float {
  float: right !important;
}

/* .wfifty {
  width: 50px !important;
} */

/* .productImg {
  width: 80px !important;
} */

.productImgWidth {
  width: 120px !important;
}

.productImgHeight {
  height: 120px !important;
}

.hfifty {
  height: 50px !important;
}

/* Gift Card Buttons */

.amt-dial-btn-gift {
  width: 100%;
  height: 8vh;
  border-radius: 4px;
  background: #92909880;
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: none;
  font-size: 1.5em;
  font-weight: 500;
  color: #0f0718;
  cursor: pointer;
}

.amt-dial-btn-gift1 {
  width: 100%;
  height: 10vh;
  border-radius: 4px;
  background: #92909880;
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: none;
  font-size: 1.5em;
  font-weight: 500;
  color: #0f0718;
  cursor: pointer;
}

.amt-dial-btn-gift2 {
  width: 100%;
  height: 17vh;
  border-radius: 4px;
  background: #92909880;
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: none;
  font-size: 1.5em;
  font-weight: 500;
  color: #0f0718;
  cursor: pointer;
}

.amt-dial-btn-gift12 {
  width: 100%;
  height: 21vh;
  border-radius: 4px;
  background: #92909880;
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: none;
  font-size: 1.5em;
  font-weight: 500;
  color: #0f0718;
  cursor: pointer;
}

.amt-dial-btn-card {
  width: 100%;
  height: 12vh;
  border-radius: 4px;
  background: #92909880;
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: none;
  font-size: 1.2vw;
  font-weight: 600;
  color: #0f0718;
  cursor: pointer;
}

.amt-dial-btn-card1 {
  width: 100%;
  height: 25vh;
  border-radius: 4px;
  background: #92909880;
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: none;
  font-size: 1.2vw;
  font-weight: 600;
  color: #0f0718;
  cursor: pointer;
}

.amt-dial-btn-cashtoKeep {
  width: 100%;
  height: 10.5vh;
  border-radius: 4px;
  background: #92909880;
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: none;
  font-size: 1.5em;
  font-weight: 500;
  color: #0f0718;
  cursor: pointer;
}

.amt-dial-btn-cashtoKeep-submit {
  /* width: 100%; */
  height: 7vh;
  border-radius: 4px;
  background: #2f3856;
  border: none;
  font-size: 1.25em;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
}

.amt-dial-btn-cashtoKeep-back {
  width: 100%;
  height: 7vh;
  border-radius: 4px;
  background: #fff;
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: 1px solid rgba(146, 144, 152, 0.5);
  font-size: 1.25em;
  font-weight: 500;
  color: #0f0718;
  cursor: pointer;
}

/* Manager Approval PIN Buttons */
.amt-dial-btn-approve {
  width: 100%;
  height: 9vh;
  border-radius: 4px;
  background: #92909880;
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: none;
  font-size: 1.5em;
  font-weight: 500;
  color: #0f0718;
  cursor: pointer;
}

.amt-dial-btn-approve2 {
  width: 100%;
  height: 19vh;
  border-radius: 4px;
  background: #92909880;
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: none;
  font-size: 1.5em;
  font-weight: 500;
  color: #0f0718;
  cursor: pointer;
}

/* .no-border-card {
  border: none !important;
} */

/* .selectedPaymentType {
  padding: 15px;
  border: 1px solid #ebeded !important;
  border-radius: 6px !important;
  background: #607d8b;
} */

/* .selectedPaymentTypeName {
  font-size: 1.2vw;
  font-weight: 600;
  color: #ffffff;
  float: right;
}

.paymt-name {
  font-size: 1.2vw;
  font-weight: 600;
  color: #374957;
  float: right;
} */

.rupees-tax {
  width: 12px !important;
  margin-top: -3px !important;
}

/* .prod-name {
  font-size: 0.9vw !important;
  color: #030d1c !important;
  font-weight: normal !important;
} */

/* .loginLogo {
  width: 60px;
  height: 50px;
  margin-top: -70px;
} */

/* .login-label {
  text-align: left;
  letter-spacing: 0.4px;
  color: #000000;
  opacity: 1;
  font-size: 1.12em;
}

.login-input {
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 6px !important;
  opacity: 1 !important;
  border: none !important;
} */

/* .btnCommon {
  background-color: #0c4da9 !important;
  color: #ffffff !important;
  width: 100%;
  border-radius: 10px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 30px !important;
  opacity: 1;
} */

/* .copyright-main-div {
  position: absolute !important;
  bottom: 10px !important;
}

.copyright {
  text-align: center;
  letter-spacing: 0px;
  color: #070000;
  font-size: 1em !important;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
} */

/* pos page css starts*/
/* .menu-icon {
  width: 23% !important;
} */

/* .pos-head-label {
  font-weight: 600 !important;
  font-size: 1.1vw !important;
  letter-spacing: 0.36px !important;
  color: #ffffff !important;
  opacity: 1 !important;
} */

/* .time-fmt {
  font-weight: 600 !important;
  font-size: 1.1vw !important;
  color: #ffffff !important;
  margin-left: 2% !important;
  letter-spacing: 0.36px !important;
  opacity: 1 !important;
}

.left {
  text-align: left !important;
}

.right {
  text-align: right !important;
}

.time {
  color: #ffffff !important;
  text-align: right;
  font-weight: 600 !important;
  font-size: 1.1vw !important;
  letter-spacing: 0.36px;
  opacity: 1;
}

.pos-logo-icon {
  width: 13% !important;
} */

/* .cardShadow {
  box-shadow: 0px 3px 6px #00000029 !important;
}
.card-radius {
  border-radius: 5px !important;
} */

/* .labelA {
  color: #0c4da9 !important;
  font-size: 1vw !important;
  font-weight: 600 !important;
}
.labelB {
  color: #000000 !important;
  font-size: 1vw !important;
  font-weight: 600 !important;
} */

.downIcon {
  width: calc(0.5625 * 3vw);
  width: calc(var(--aspect-ratio) * 3vw);
  height: calc(0.5625 * 3vw);
  height: calc(var(--aspect-ratio) * 3vw);
  /* width: 22px !important; */
  /* float: right !important; */
}

.paid-icon {
  width: 8px !important;
  margin-top: -3px !important;
}

/* .amt-text-align {
  text-align: right;
} */

/* .tax-dis-lbl {
  padding: 0 15px;
  text-align: left !important;
  font-size: 1vw !important;
  font-weight: 600 !important;
  color: #000000 !important;
  opacity: 1 !important;
} */

.table-stl {
  /* width: 100%; */
  /* height: calc(var(--aspect-ratio) * 66.5vw); */
  /* overflow: auto !important; */
  /* border: none !important; */
  height: 83vh !important;
}

.offerName {
  font-size: 0.8em !important;
  color: #0f0718;
  font-weight: 400;
  font-family: Inter !important;
}

.offerName1 {
  line-height: 0;
  font-size: 0.77vw !important;
  color: #929098;
  font-weight: 550;
}

.tableFooterRow {
  padding: 7px;
  background-color: "";
}

.totalBtn:hover,
.totalBtn:active,
.totalBtn:focus {
  width: 100%;
  border-radius: 7px;
  background: #2f3856 !important;
  color: #fff;
}

/* .ant-btn:hover{
  background: #a4cd38 !important;
  color: #fff !important;
  border-color: #a4cd38 !important;
} */
.totalLabel {
  color: #ffffff;
  font-size: 1.5vw;
  font-weight: bold;
}

.productSpecImg {
  width: calc(0.5625 * 5.5vw);
  width: calc(var(--aspect-ratio) * 5.5vw);
  height: calc(0.5625 * 5.5vw);
  height: calc(var(--aspect-ratio) * 5.5vw);
}

.productSpecImgDec {
  width: calc(0.5625 * 3.5vw);
  width: calc(var(--aspect-ratio) * 3.5vw);
}

/* .addProductsCard,
.searchProductsCard,
.quantityUpdateCard {
  padding: 8px;
  width: 100%;
  background: #f9f9f9;
} */

.addProductsCard,
.quantityUpdateCard {
  /* height: calc(var(--aspect-ratio) * 81.25vw); */
  height: calc(0.5625 * 68.5vw);
  height: calc(var(--aspect-ratio) * 68.5vw);
  /* height: calc(var(--size) - 15px); */
  /* height: 78vh; */
}

.productScroll {
  /* height: calc(var(--aspect-ratio) * 66vw) !important; */
  height: 82vh !important;
}

.productScroll1 {
  /* height: calc(var(--aspect-ratio) * 66vw) !important; */
  height: 68.5vh !important;
}

.closeTillScroll1 {
  height: 68.5vh !important ;
}

.closeTillScroll2 {
  height: 65vh !important;
}

.openTillScroll1 {
  height: 34vh !important;
}

.offerProductScroll1 {
  /* height: calc(var(--aspect-ratio) * 66vw) !important; */
  height: 50vh !important;
}

.productScrollKiosk {
  /* height: calc(var(--aspect-ratio) * 66vw) !important; */
  height: 70vh !important;
}

.productCategorySuper {
  /* height: calc(var(--aspect-ratio) * 66vw) !important; */
  height: 33vh !important;
}

/* .prodCard {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #ebeded;
} */

.searchIcon {
  width: 18px !important;
}

.dialer-btn-image {
  height: 95% !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}

.dialer-btn {
  height: calc(0.5625 * 10.6vw);
  height: calc(var(--aspect-ratio) * 10.6vw);
  width: calc(0.5625 * 12vw);
  width: calc(var(--aspect-ratio) * 12vw);
  /* background: #ebeded;
  box-shadow: 0px 3px 6px #0000004c;
  border-radius: 15px;
  border: 1px solid #ebeded;
  font-size: 1.7vw;
  font-weight: 600; */
}

.addBySKUbtn,
.addByUPCbtn {
  height: calc(0.5625 * 10.6vw);
  height: calc(var(--aspect-ratio) * 10.6vw);
  width: calc(0.5625 * 12vw);
  width: calc(var(--aspect-ratio) * 12vw);
  /* box-shadow: 0px 3px 6px #0000004c; */
  /* background: #607d8b;
  color: #ffffff;
  border: 1px solid #607d8b;
  border-radius: 15px;
  font-size: 2vw;
  font-weight: 600; */
}

.productSpecChange {
  /* height: calc(6.1vw - 10px); */
  height: calc(0.5625 * 8vw);
  height: calc(var(--aspect-ratio) * 8vw);
  /* width: calc(6vw - 10px); */
  /* width: 100%;
  box-shadow: 0px 3px 6px #0000004c;
  background: #607d8b;
  color: #ffffff;
  border: 1px solid #607d8b;
  border-radius: 15px; */
}

/* .productSpecChange {
  height: calc(5.7vw - 10px);
  width: calc(6vw - 10px);
} */

.infoButton {
  font-size: 3.2vw;
}

/* .addprodback {
  width: 30px;
  height: 20px;
} */

.specCol {
  /* margin-bottom: 11px; */
  margin-left: 8px;
}

.specCol-dash {
  /* margin-bottom: 0px; */
  margin-left: 8px;
}

button {
  outline: none;
}

.dialer-btn-image2 {
  height: 92% !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}

.dialer-btn-image3 {
  height: 92% !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}

.aspect-ratio-for-update-qty {
  max-width: 400px !important;
  height: auto !important;
  min-height: 40px !important;
}

.aspect-ratio-for-firstleft-icons {
  max-width: 400px !important;
  height: auto !important;
  min-height: 40px !important;
}

/*dashboard css starts*/
/* .workType {
  padding: 24px;
} */

/* .imgWorkType {
  height: 37px;
  width: 33px;
  margin-bottom: 15px;
} */

/* .cardWorkType {
  height: 25vh;
  padding: 30px;
  box-shadow: 1px 2px 7px -3px !important;
} */

.logoIcon {
  width: 96px !important;
  height: 77px !important;
}

/* .sync {
  width: 40px !important;
  height: 40px !important;
}

.logout {
  width: 40px !important;
  height: 40px !important;
} */

.dbbackup {
  width: 40px !important;
  height: 40px !important;
}

/* .dashboard-label {
  font-size: 1.3vw !important;
  color: #000000 !important;
  text-align: center;
} */

/* .dashboard-first-row {
  margin: 3vh 0vh 0vh 3vh !important;
} */

/* .dashboard-info {
  text-align: left !important;
  font-size: 1.2vw !important;
  color: #ffffff !important;
} */

/* .dashboard-greet {
  float: right !important;
  font-size: 1.2vw !important;
  color: #ffffff !important;
} */

.col-text-align {
  text-align: center !important;
}

/* .dashboard-sync-logout-mainrow {
  margin: 15vh 0vh 0vh 3vh !important;
  float: right;
  padding-left: 1.7em;
  padding-right: 1.7em;
} */

/*dashboard css ends */

/* sync page */
/* .rowGap {
  margin-top: 10px;
} */

/* .logoIcon {
  width: 40px;
  height: 32px;
} */

/* .logoIconDashboard {
  width: 60px;
  height: 60px;
} */

/* .mainDivStyl {
  padding: 10px;
} */

/* .iconStyl {
  font-size: 30px;
  color: green !important;
} */
/*ends*/

/* Customer Selection Radio Button */
/* .radio-lbl-cus,
.radio-lbl-ph {
  font-weight: 600;
}

.radio-lbl-ph {
  padding-left: 22px;
} */

/* .customer-search-input {
  height: 7vh;
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
} */

.customer-search-input-suffix {
  padding: 20px;
  margin-right: -12px;
  border-radius: 5px;
  border-left: 1px solid #cfd9e0;
}

.customer-search-input-suffix:hover {
  border: 1px solid #d9d9d9;
}

.custSearchIcon {
  font-size: 1vw;
  color: #37474f;
}

/* .customerSearchList {
  max-height: 60vh;
  min-height: 55vh;
} */

/* .customerDetailsCard {
  background: #0c4da90d;
  margin-bottom: 10px;
} */

/* .customerName {
  margin-bottom: 0;
  color: #000000;
  font-size: 0.8vw;
  font-weight: 600;
} */

/* .customerStatus {
  margin-bottom: 0;
  color: #0c4da9;
  font-size: 0.8vw;
  font-weight: 600;
} */

/* .noResultsText {
  font-size: 1vw;
  color: #b9b9b9;
} */

/* .addNewCustomer {
  font-size: 1vw;
  color: #0c4da9;
} */

.addCustArrow {
  color: #000000;
  font-size: 1.5vw;
}

.addCustTitle {
  margin-bottom: 0px;
  font-size: 1.5vw;
  padding-left: 20px;
}

/* .cutomerDetailsField {
  font-size: 1em !important;
  color: #000000;
} */

.customerDetailsSec {
  padding-left: 30px;
}

/* .cutomerDetailsInput {
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ebeded;
} */

.cutomerDobInput {
  width: 100%;
  padding: 8px;
  border-radius: 3px;
  margin-bottom: 10px;
  border: none;
}

.cutomerDobInput .ant-picker-input {
  flex-direction: row-reverse;
}

.cutomerDobInput .ant-picker-input input::-webkit-input-placeholder {
  font-size: 0.9vw;
  color: #929098;
  margin-left: 1vw;
}

.cutomerDobInput .ant-picker-input input:-ms-input-placeholder {
  font-size: 0.9vw;
  color: #929098;
  margin-left: 1vw;
}

.cutomerDobInput .ant-picker-input input::placeholder {
  font-size: 0.9vw;
  color: #929098;
  margin-left: 1vw;
}

.ant-picker-suffix {
  margin-right: 0.9vw;
}

/* .customerDetailsBtns {
  justify-content: center;
  padding-top: 20px;
} */

/* .customerDetailsCancelBtn,
.customerDetailsSubmitBtnBtn {
  color: #ffffff;
  border-radius: 5px;
  background: #607d8b;
} */

/* .customerDetailsCancelBtn:hover,
.customerDetailsCancelBtn:focus {
  background: #707070;
  border-color: #607d8b;
  border-radius: 5px;
  color: #ffffff;
} */

.customerDetailsSubmitBtnBtn:hover,
.customerDetailsSubmitBtnBtn:focus {
  background: #0c4da9;
  border-color: #0c4da9;
  border-radius: 5px;
  color: #ffffff;
  margin-right: "2vw";
}

/* .customerDetailsCancelBtn {
  background: #707070;
} */

/* .customerDetailsSubmitBtnBtn {
  margin-left: 30px;
  background: #0c4da9;
} */

/* .formItem {
  margin-bottom: 10px;
} */

/* Ends */

/* Returns Modal CSS */
.returnsButton {
  width: 15%;
  height: 6vh;
  font-size: 18px;
  margin-top: 50px;
  color: #ffffff !important;
  border-radius: 5px;
  background: #607d8b !important;
  float: right;
}

.returnsButton:hover {
  color: #ffffff !important;
  border-radius: 5px;
  background: #607d8b !important;
  border-color: #607d8b;
}

.returnProductsTable {
  width: 100%;
  border: 1px solid #ebeded;
  border-collapse: collapse;
  border-radius: 5px 5px 0px 0px !important;
}

.returnProductsTableThead {
  background-color: #ebeded;
}

.returnProductsTableTd,
.returnProductsTableTh {
  text-align: center;
  padding: 15px;
}

.returnProductsTableTd,
.returnProductsTableTdName {
  font-size: 12px;
  font-weight: 600;
}

.returnProductsTableTdName,
.returnProductsTableThName {
  text-align: left;
}

tr:hover {
  background-color: #ebeded;
}

.returnsQtyBtns {
  color: #607d8b;
}

.returnQunatity {
  display: flex;
  justify-content: center;
}

.returnsQtyInc,
.returnsQtyDec {
  color: #607d8b;
  padding-top: 8px;
  font-size: 15px;
}

.returnsQtyInc {
  padding-left: 10px;
}

.returnsQtyDec {
  padding-right: 10px;
}

.returnsQtyInput {
  border-radius: 5px;
  border: 1px solid #ebeded;
  text-align: center;
}

.returnsQtyFormitem {
  width: 15%;
  margin-bottom: 0;
}

/* Ends */

/*pos page css ends*/

/* pos new dialer pad*/
.wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(4, 2fr);
  grid-gap: 2px;
  padding: 15px 8px 8px;
  height: 61vh;
}

.item2 {
  grid-column: 2/3;
  grid-row: 1/2;
  text-align: center;
}

.item3 {
  grid-column: 3/4;
  grid-row: 1/2;
  text-align: center;
}

.item4 {
  grid-column: 4/5;
  grid-row: 1/2;
  text-align: center;
}

.item5 {
  grid-column: 5/6;
  grid-row: 1/2;
  text-align: center;
}

/*second row*/
.item6 {
  grid-column: 2/3;
  grid-row: 2/2;
  text-align: center;
}

.item7 {
  grid-column: 3/4;
  grid-row: 2/2;
  text-align: center;
}

.item8 {
  grid-column: 4/5;
  grid-row: 2/2;
  text-align: center;
}

.item9 {
  grid-column: 5/6;
  grid-row: 2/2;
  text-align: center;
}

/*second row ends*/

/*third row*/
.item10 {
  grid-column: 2/3;
  grid-row: 3/3;
  text-align: center;
}

.item11 {
  grid-column: 3/4;
  grid-row: 3/3;
  text-align: center;
}

.item12 {
  grid-column: 4/5;
  grid-row: 3/3;
  text-align: center;
}

.item13 {
  grid-column: 5/6;
  grid-row: 3/3;
  text-align: center;
}

/* third row ends*/

/*fourth row starts*/
.item14 {
  grid-column: 2/3;
  grid-row: 4/4;
  text-align: center;
}

.item15 {
  grid-column: 3/4;
  grid-row: 4/4;
  text-align: center;
}

.item16 {
  grid-column: 4/5;
  grid-row: 4/4;
  text-align: center;
}

.item17 {
  grid-column: 5/6;
  grid-row: 4/4;
  text-align: center;
}

.item18 {
  grid-column: 2/3;
  grid-row: 5/5;
  text-align: center;
  bottom: 0;
}

/*fourth row ends*/

/* pos new dialer pad ends*/

/* .add-product-save,
.add-product-pay {
  background: #607d8b !important;
  color: #ffffff !important;
  font-size: 1.5vw !important;
  font-weight: 600 !important;
  border-radius: 7px;
}

.add-product-pay {
  float: right !important;
  background: #ff8c00 !important;
} */

.add-product-save,
.add-product-pay {
  height: calc(0.5625 * 6.2vw);
  height: calc(var(--aspect-ratio) * 6.2vw);
  width: 100%;
}

/* .addProductInput {
  width: 97%;
  padding: 3%;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 1.8vw;
  font-weight: 600;
  text-align: center;
} */

/* START - custom CSS While using functional components */
/* .sectionStyle {
  width: 100% !important;
  height: 100% !important;
  padding-top: 27vh;
  background-image: url("../assets/images/backgroundImage.png");
  background-repeat: no-repeat;
  background-size: cover;
} */

/* .spinLoader {
  font-size: 24;
} */

.ant-table-selection-col,
.ant-table-selection-column {
  display: none;
}

/* END - custom CSS  While using functional components */

/* Order History Modal CSS*/
.backArrowOrderHistory,
.productSearchIcon {
  font-size: 1.8vw;
}
.productSearch {
  font-size: 1vw;
}
.productSearch .ant-input::-webkit-input-placeholder {
  /* font-size: 1em;
  font-weight: 400;
  color: #929098; */
  font-size: 1em !important;
  font-weight: 500 !important;
  font-family: Inter;
  color: #929098;
}
.productSearch .ant-input:-ms-input-placeholder {
  /* font-size: 1em;
  font-weight: 400;
  color: #929098; */
  font-size: 1em !important;
  font-weight: 500 !important;
  font-family: Inter;
  color: #929098;
}
.productSearch .ant-input,
.productSearch .ant-input::placeholder {
  /* font-size: 1em;
  font-weight: 400;
  color: #929098; */
  font-size: 1em !important;
  font-weight: 500 !important;
  font-family: Inter;
  color: #929098;
}
.productSearchIcon {
  font-size: 1.6vw;
}

.backArrowParkedOrderHistory {
  padding-top: 5px;
  font-size: 1.2vw;
}

.orderHistorySearchIcon {
  font-size: 1.5vw;
  color: #37474f;
}

/* .orderHistoryModalTitle {
  text-align: left;
  font-size: 1.5vw;
  font-weight: 600;
  color: #0c4da9;
} */

/* .parkedBillsHistoryModalTitle {
  text-align: left;
  font-size: 1.3vw;
  font-weight: 600;
  color: #0c4da9;
} */

/* .orderHistorySearchInput {
  height: 7vh;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
} */

.orderHistorySearchInputSuffix {
  padding: 20px;
  /* margin-top: -8px;
  margin-bottom: -8px; */
  margin-right: -12px;
  border-radius: 5px;
  /* border-left: 1px solid #CFD9E0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; */
  border-left: 1px solid #cfd9e0;
}

.orderHistorySearchInputSuffix:hover {
  border: 1px solid #1890ff;
}

/* .orderHistoryAttribute {
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
} */

.attributes {
  margin-bottom: 0;
  font-size: 16px;
  color: #a6adb4;
}

.attributesDropdown {
  float: right;
  font-size: 24px;
}

.orderHistoryCard,
.orderHistoryTabs,
.oderHistoryDetailsCard {
  width: 100%;
}

.orderHistoryCard,
.orderHistoryScroll {
  min-height: 80vh;
}

/* .oderHistoryDetailsCard {
  background: #0c4da90d;
  margin-bottom: 10px;
} */

/* .orderDate {
  color: #7d7d7d;
  font-size: 0.9vw;
}

.orderCustomerName {
  float: right;
  color: #7d7d7d;
  font-size: 0.9vw;
} */

/* .dropdownCircleIcon {
  float: right;
} */

/* .orderNum {
  margin-bottom: 0;
  color: #000000;
  font-size: 0.9vw;
  font-weight: 600;
}

.orderStatus {
  margin-bottom: 0;
  float: right;
  color: #0c4da9;
  font-size: 0.9vw;
  font-weight: 600;
} */

/* .orderDetailsTable {
  width: 100%;
  margin-top: 10px;
}

.orderDetailsTableHeader {
  font-size: 0.9vw;
  color: #607d8b;
}

.orderDetailsTableTh {
  text-align: center;
}

.orderDetailsData {
  text-align: center;
  font-size: 0.8vw;
  color: #000000;
}

.orderDetailsDesc {
  text-align: left;
}

.orderDetailsTaxes,
.orderDetailsTotal {
  width: 100%;
  font-size: 0.9vw;
  font-weight: 600;
} */

/* .orderDetailsButtons {
  width: 100%;
} */

.rePrintBtn:hover,
.returnBtn:hover {
  background: #607d8b;
  color: white;
  border-radius: 5px;
  font-size: 1vw;
  font-weight: 600;
}

/* .returnBtn {
  float: right;
} */

.parkedModalContent {
  font-size: 1.3vw;
  font-weight: normal;
  color: #0c4da9;
  margin-bottom: 0;
  padding-top: 3px;
}

.parkedModalIcon {
  float: right;
  padding-left: 30px;
  padding-right: 20px;
  color: #20b883;
  font-size: 1.62vw;
}

.syncnowBtn {
  left: 40%;
  margin-bottom: 8px;
}

/* Ends */

/* Product Category Filters CSS */

/* .productsSearchCol {
  padding-right: 8px;
} */

/* .categoryFiltersCol {
  border: 1px solid #ebeded;
  border-right: none;
  border-bottom: none;
  margin-top: -10px;
} */

/* .productsFilterBtn {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid #cfd9e0;
} */

.productsFilterBtn,
.productsFilterBtn:hover,
.productsFilterBtn:focus {
  border: 1px solid #cfd9e0;
}

/* .productSearchSuffix {
  font-size: 35px;
  color: #bac8d3;
} */

/* .filterIcon {
  height: 25px;
  width: 25px;
} */

/* Ends */

/* Cash/Carry Modal CSS */
.cashCarryBtn {
  width: 70%;
  float: right;
  background-color: #ebeded;
  border-color: #ebeded;
  border-radius: 3px;
}

.deliveryBtn {
  width: 70%;
  margin-left: 20px;
  background-color: #ebeded;
  border-color: #ebeded;
  border-radius: 3px;
}

.cashCarryBtn:hover,
.deliveryBtn:hover,
.cashCarryBtn:focus,
.deliveryBtn:focus {
  background-color: #ebeded;
  border-color: #ebeded;
  border-radius: 3px;
  color: #000000;
}

/* Ends */

/* Print Bill Popup css */
.printBillText {
  text-align: center;
  color: #0c4da9;
  font-size: 18px;
}

.yesBtn,
.noBtn {
  background-color: #ebeded;
  border-color: #ebeded;
  border-radius: 3px;
  width: 50%;
}

.yesBtn:hover,
.noBtn:hover,
.yesBtn:focus,
.noBtn:focus {
  background-color: #ebeded;
  border-color: #ebeded;
  border-radius: 3px;
  color: #000000;
}

.yesBtn {
  float: right;
}

.noBtn {
  margin-left: 20px;
}

/* Ends */

@media print {
  body * {
    margin: 0;
    padding-left: 5px;
    visibility: hidden !important;
    zoom: 100% !important;
    box-shadow: none !important;
  }

  .section-not-to-print,
  .section-not-to-print * {
    display: none !important;
  }

  .section-to-print,
  .section-to-print * {
    visibility: visible !important;
    width: 100% !important;
  }

  .section-to-print {
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
  }
}

.btn-card {
  height: 89vh;
  margin-top: 10px !important;
}

.btn-till {
  background-color: #314eaf !important;
  color: #ffffff !important;
  width: 60%;
  margin-top: 2%;
  font-weight: bold;
  font-size: large;
}

.btn-container {
  padding: 15%;
  text-align: center;
}

.logo-card {
  height: 7vh;
  padding: 4px !important;
}

/* .main-container {
  background-color: #f8fbff;
  height: 100vh;
  padding: 10px;
} */

/* .main-card-container {
  margin-top: 10px !important;
  background-color: #f8fbff !important;
} */

.main-card-first-open-till {
  height: 89vh;
}

/* .main-card-first {
  height: 87.5vh;
} */

.card-notes {
  margin-top: 10px !important;
}

/* .main-card-second-open-till {
  height: 81.2vh;
  margin-top: 1px !important;
} */

.btn-container {
  padding: 15%;
  text-align: center;
}

/* .card-shadow {
  box-shadow: 1px 2px 4px -3px !important;
  border-radius: 5px !important;
} */
.logo-card {
  height: 7vh;
  padding: 4px !important;
}

/* .logo-icon {
  width: 40px !important;
  height: 32px !important;
} */
/* .currency {
  background-color: #dfeafd !important;
  width: 100px !important;
  font-weight: 700px !important;
  text-align: right !important;
  border: none !important;
}

.currency-count {
  font-weight: bold !important;
  text-align: center !important;
  width: 40% !important;
  font-size: 18px !important;
}

.total-input {
  width: 100 px !important;
  font-weight: 700px !important;
  text-align: right !important;
  border: none !important;
} */
/* .col-align {
  text-align: right !important;
}

.col-amonut {
  text-align: right !important;
} */

.formItem {
  margin-bottom: 10px !important;
  line-height: 10px !important;
}

/* .btn-confirm {
  width: 49.5%;
  margin-left: 1%;
  background-color: #314eaf !important;
  color: #ffffff !important;
  font-size: 1.2vw;
} */

/* .btn-cancel {
  width: 49.5%;
  background-color: #ffffff !important;
  color: #000000 !important;
  font-size: 1.2vw;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* .heading-till {
  margin-bottom: 0;
  padding-top: 0.5%;
  font-size: 16px;
  font-weight: 600;
} */

.tillScroll {
  height: 79vh !important;
}

.closeTillScroll {
  height: 79vh !important;
}

.transactionAmtInput,
.transactionAmtInput:focus {
  width: 100%;
  padding: 9px;
  background: #fff;
  border-color: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  outline: none;
  box-shadow: none;
  margin-bottom: 1%;
  height: "6vh" !important;
  margin-top: 1vh;
  font-size: 1em !important;
  font-weight: 400 !important;
}

.transactionAmtInputClose,
.transactionAmtInputClose:focus {
  width: 90%;
  padding: 9px;
  background: #fff;
  border-color: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  outline: none;
  box-shadow: none;
  margin-bottom: 1%;
  height: "5vh" !important;
  font-size: 1em !important;
  font-weight: 400 !important;
}

.transactionAmtInput,
.transactionAmtInput:focus {
  width: 90%;
  padding: 9px;
  background: #fff;
  border-color: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  outline: none;
  box-shadow: none;
  margin-bottom: 1%;
  height: "5vh" !important;
  font-size: 1em !important;
  font-weight: 400 !important;
}

/* fdfdfdf*/

/* Product Info Modal */

/* .prod-head {
  font-size: 18px;
  font-weight: 600;
} */

.prod-weight,
.prod-pack,
.prod-offer,
.prod-descp-price-mrp {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
}

.prod-descp-price-mrp {
  text-decoration: line-through;
}

/* .prod-descp-price {
  color: #535665;
} */

.prod-pack {
  color: #707070;
}

.prod-offer {
  color: #ff0000;
}

/* .prod-qty {
  display: flex;
} */

.prod-qty-dec,
.prod-qty-inc {
  padding: 8px;
  font-size: 18px;
  color: #607d8b;
  border: 0.5px solid #0000004c;
}

.prod-qty-dec {
  border-right: none;
}

.prod-qty-inc {
  border-left: none;
}

/* .prod-qty-input {
  border-radius: 0px;
  padding: 8px;
  border: 1px solid #0000004c;
  text-align: center;
} */

/* .prod-qty-form {
  width: 20%;
  margin-bottom: 0;
} */

.prod-qty-btn:hover,
.prod-qty-btn:focus {
  width: 70%;
  background-color: #0c4da9;
  color: #ffffff;
  border: 1px solid #0c4da9;
  border-radius: 0px;
  float: right;
}

.prod-select {
  padding-top: 20px;
  padding-right: 15px;
  font-size: 24px;
  float: right;
}

.prod-selected-col {
  border: 1px solid #607d8b;
  background: #607d8b;
  border-radius: 0px 2px 2px 0px;
  color: #ffffff;
}

.prod-col {
  padding: 5px 0 5px 10px;
}

.prod-select-div {
  margin-bottom: 10px;
  background: #eef5ff;
  border: 1px solid #d3e5ff;
  border-radius: 5px;
}

.prod-div {
  margin-bottom: 10px;
  border: 1px solid #0000004c;
  border-radius: 5px;
}

/* .aspect-ratio {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
} */

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .addProductsCard,
  .searchProductsCard,
  .quantityUpdateCard {
    padding: 30px;
    padding-top: 10px;
  }

  .addProductsCard,
  .quantityUpdateCard {
    /* height: calc(var(--aspect-ratio) * 78.25vw); */
    height: 82vh;
  }

  .searchProductsCard,
  .quantityUpdateCard {
    padding: 8px;
  }

  .addProductInput {
    padding: 6% !important;
  }

  .filterIcon {
    height: 12px !important;
    width: 12px !important;
  }

  /* .quantity-btn-zero {
    height: 6vh;
  } */

  /* .qty-card-row {
    padding-top: 10px;
  } */

  /* .qty-img {
    width: 25px !important;
  } */

  .qty-btn-back {
    width: 20px;
    height: 15px;
  }

  .qty-btn-close {
    width: 15px;
    height: 15px;
  }

  .delete-icon {
    width: 10px !important;
    margin-top: -6px !important;
  }

  .productImgWidth {
    width: 80px !important;
  }

  .productImgHeight {
    height: 80px !important;
  }

  /* .offersCardList {
    min-height: 34vh;
  } */
}

@media only screen and (max-width: 595px) {
  .sectionStyle {
    width: 100% !important;
    height: 100% !important;
    padding-top: 10vh;
    background-image: url(/static/media/backgroundImgMobile.21e8afef.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .login-card {
    max-width: 300px;
    margin-left: 2vh !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 0 !important;
    border: 1px solid #ebeded !important;
  }

  .loginLogo {
    width: 60px;
    height: 50px;
    margin-top: 0;
    margin-left: -5px;
    margin-bottom: 20px;
  }

  .copyright-main-div {
    display: none;
  }

  .cardWorkType {
    height: 18vh;
    padding: 0;
    box-shadow: 1px 2px 7px -3px !important;
  }

  .dashboard-label {
    font-size: 1em !important;
    color: #000000 !important;
  }

  .dashboard-sync-logout-mainrow {
    margin: -22% 0 0 !important;
  }
}

@media only screen and (min-width: 1920px) {
  .logoIconDashboard {
    width: 80px;
    height: 80px;
  }

  .imgWorkType {
    height: 80px;
    width: 70px;
    margin-bottom: 15px;
  }

  .sync {
    width: 60px !important;
    height: 60px !important;
  }

  .logout {
    width: 60px !important;
    height: 60px !important;
  }

  .menu-icon {
    width: 28% !important;
  }

  /* .quantity-btn,
  .quantity-btn-ok,
  .quantity-btn-zero,
  .dialer-btn,
  .addBySKUbtn,
  .addByUPCbtn {
    border-radius: 20px;
  } */

  /* .quantity-btn-zero {
    height: 10vh;
  } */

  /* .qty-card-row {
    padding-top: 30px;
  } */

  /* .qty-img {
    width: 50px !important;
  } */

  .delete-icon {
    width: 15px !important;
    margin-top: 6px !important;
  }

  /* .table-stl {
    height: calc(var(--aspect-ratio) * 70vw);
  } */

  .productSpecChange {
    height: calc(0.5625 * 8.6vw);
    height: calc(var(--aspect-ratio) * 8.6vw);
    border-radius: 20px;
  }

  /* .addProductsCard,
  .quantityUpdateCard {
    height: calc(var(--aspect-ratio) * 70.5vw);
  } */

  .addProductInput {
    padding: 4%;
  }

  .productScroll {
    /* height: calc(var(--aspect-ratio) * 70.5vw) !important; */
    height: 81vh !important;
  }

  .productInfoCard {
    height: calc(0.5625 * 17vw) !important;
    height: calc(var(--aspect-ratio) * 17vw) !important;
  }

  .prodQtyCard {
    height: calc(0.5625 * 50.1vw) !important;
    height: calc(var(--aspect-ratio) * 50.1vw) !important;
  }

  .qty-card {
    height: calc(0.5625 * 10vw) !important;
    height: calc(var(--aspect-ratio) * 10vw) !important;
  }

  .qty-card-row {
    padding-top: 5% !important;
  }

  .quantityUpdateInput {
    padding: 5% !important;
  }

  .quantity-btn-zero {
    height: calc(0.5625 * 7.5vw) !important;
    height: calc(var(--aspect-ratio) * 7.5vw) !important;
  }
}

/* .prod-total {
  width: 100%;
  position: absolute !important;
  bottom: 0px !important;
} */

/* Quantity Offers, Stock, Sales, Info Pages */

/* .txt-cen {
  text-align: center !important;
} */

.qty-card {
  height: calc(0.5625 * 9.5vw);
  height: calc(var(--aspect-ratio) * 9.5vw);
  /* width: 100% !important; */
}

/* .qty-card-row {
  padding-top: 3%;
} */

.qty-img {
  width: calc(0.5625 * 4.5vw) !important;
  width: calc(var(--aspect-ratio) * 4.5vw) !important;
}

/* .no-border-crd {
  border: none !important;
} */

.padding-ten {
  padding: 10 !important;
}

/* .selected {
  border-left-style: solid;
  border-left-width: 5px;
  border-left-color: #000000;
  box-shadow: 0px 0px 10px #607d8b1a;
  border-radius: 5px;
  color: #374957;
} */

.qty-lbl {
  font-size: 1.5vw !important;
  font-weight: 600 !important;
  color: #374957 !important;
}

.productInfoCard {
  height: calc(0.5625 * 20vw);
  height: calc(var(--aspect-ratio) * 20vw);
  width: 100% !important;
  /* border-radius: 6px; */
}

.prodInfoImage {
  width: 100%;
  height: 100%;
}

.prodInfoCol {
  padding-left: 10px;
}

/* .prod-name-details {
  font-size: 1vw !important;
  font-weight: 600 !important;
  letter-spacing: 0.36px;
  color: #030d1c;
}

.prod-price-lbl {
  padding-top: 5px;
  font-size: 0.9vw !important;
  color: #030d1c !important;
  font-weight: 600 !important;
  letter-spacing: 0.36px;
} */

.prod-desc-lbl {
  /* padding-top: 5px;
  margin-bottom: 0;
  font-size: 0.9vw !important;
  color: #030d1c !important;
  font-weight: normal !important;
  width: 90%;
  overflow: hidden; */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.prodQtyCard {
  height: calc(0.5625 * 47.6vw);
  height: calc(var(--aspect-ratio) * 47.6vw);
  box-shadow: rgb(0 0 0 / 16%) 0px 2px 4px;
  border-radius: 6px;
  /* padding: 20px; */
}

.emptyCard {
  height: calc(0.5625 * 47.6vw);
  height: calc(var(--aspect-ratio) * 47.6vw);
  /* background-color: #fff; */
  /* border-radius: 6px; */
}

/* .quantityUpdateInput {
  padding: 3%;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 1.5vw;
  font-weight: 600;
  text-align: center;
} */

/* .quantity-btn {
  border-radius: 12px;
  background: #ebeded;
  box-shadow: 0px 3px 6px #0000004c;
  border: 1px solid #ebeded;
  font-size: 1.6vw;
  font-weight: 600;
} */

.quantity-btn-zero {
  /* width: 100%; */
  height: calc(0.5625 * 6.5vw);
  height: calc(var(--aspect-ratio) * 6.5vw);
  /* border-radius: 12px;
  background: #ebeded;
  box-shadow: 0px 3px 6px #0000004c;
  border: 1px solid #ebeded;
  font-size: 1.6vw;
  font-weight: 600; */
}

/* .quantity-btn-ok {
  width: 100%;
  height: 100%;
  background: #20b883;
  border: 1px solid #20b883;
  border-radius: 12px;
  box-shadow: 0px 3px 6px #0000004c;
  font-size: 1.6vw;
  font-weight: 600;
}

.qty-btn-back {
  width: 30px;
  height: 25px;
}

.qty-btn-close {
  width: 25px;
  height: 25px;
} */

.offersCard {
  height: calc(0.5625 * 47.5vw);
  height: calc(var(--aspect-ratio) * 47.5vw);
  box-shadow: rgb(0 0 0 / 16%) 0px 2px 4px;
  border-radius: 6px;
}

.offersCardList {
  min-height: calc(0.5625 * 40vw);
  min-height: calc(var(--aspect-ratio) * 40vw);
}

.infoCardList {
  min-height: calc(0.5625 * 40vw);
  min-height: calc(var(--aspect-ratio) * 40vw);
  overflow-x: hidden !important;
}

.infoTitle {
  margin-bottom: 0;
  font-size: 0.9vw;
  letter-spacing: 0.24px;
  color: #607d8b;
}

.infoDesc {
  font-size: 0.9vw;
  letter-spacing: 0.24px;
  color: #000000;
}

.ribbon {
  position: relative;
}

.ribbon-1 {
  width: 50px;
  height: 50px;
  background-color: #02c437;
  top: 150px;
  left: -10px;
}

.ribbon-1::before {
  content: "";
  position: relative;
  top: -10px;
  right: 0px;
  border-left: 5px solid #02c437;
  border-right: 5px solid transparent;
  border-top: 5px solid transparent;
  border-bottom: 5px solid #02c437;
}

.ribbon-1::after {
  content: "";
  position: relative;
  top: 0;
  left: -1px;
  border-left: 25px solid #fff;
  border-right: 25px solid transparent;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
}

/* Layout 2 */

/* .mainRow {
  height: calc(var(--aspect-ratio) * 81.57vw);
} */

.updateOptions {
  height: calc(0.5625 * 5vw);
  height: calc(var(--aspect-ratio) * 5vw);
}

/* .layout2Bgd {
  height: calc(var(--aspect-ratio) * 80vw);
  background-image: url("../assets/images/pizza-dark.png");
} */

/* .productScroll2 {
  height: calc(var(--aspect-ratio) * 54vw) !important;
} */

.productCategoryScroll {
  height: calc(0.5625 * 17vw) !important;
  height: calc(var(--aspect-ratio) * 17vw) !important;
}

.addOnSelectionScroll {
  height: calc(0.5625 * 5vw) !important;
  height: calc(var(--aspect-ratio) * 5vw) !important;
}

.productCartScroll {
  height: calc(0.5625 * 40vw) !important;
  height: calc(var(--aspect-ratio) * 40vw) !important;
}

.dineBtn,
.dineBtn:hover,
.dineBtn:focus {
  border: none;
  box-shadow: 0px 3px 6px #00000029;
}

.takeawayBtn,
.takeawayBtn:hover,
.takeawayBtn:focus {
  border: none;
  box-shadow: 0px 3px 6px #00000029;
}

.addOnSelectionModal {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #dfdfdf;
  opacity: 1;
}

.addButton,
.addButton:hover,
.addButton:focus {
  background: #4c4c4c;
  border: none;
  box-shadow: none;
}

.cancelButton,
.cancelButton:hover,
.cancelButton:focus {
  background: #848484;
  border: none;
  box-shadow: none;
}

/* Tables Management */

.tablesScroll {
  height: calc(0.5625 * 68.5vw) !important;
  height: calc(var(--aspect-ratio) * 68.5vw) !important;
}

.reservedTableScroll {
  height: calc(0.5625 * 67vw) !important;
  height: calc(var(--aspect-ratio) * 67vw) !important;
}

.reservedTableRow:nth-child(even) {
  background: #f3f1f1;
}

.selectedItemsScroll {
  height: calc(0.5625 * 30vw) !important;
  height: calc(var(--aspect-ratio) * 30vw) !important;
}

.addOnsModalScroll {
  height: calc(0.5625 * 16vw) !important;
  height: calc(var(--aspect-ratio) * 16vw) !important;
}

.orderItemsScroll {
  height: calc(0.5625 * 16vw) !important;
  height: calc(var(--aspect-ratio) * 16vw) !important;
}

.orderCardsScroll {
  height: calc(0.5625 * 75vw) !important;
  height: calc(var(--aspect-ratio) * 75vw) !important;
}

.barcodeSelectedItemsScroll {
  height: calc(0.5625 * 16vw) !important;
  height: calc(var(--aspect-ratio) * 16vw) !important;
}

.center {
  text-align: center;
}

.orderIdInput,
.orderIdInput:hover,
.orderIdInput:focus {
  background: #f5f5f7;
  border: 1px solid #f5f5f7 !important;
  outline: none !important;
  box-shadow: 0px 3px 6px #00000029 !important;
}

/* .orderIdInput::placeholder {
  font-size: 12px !important;
  font-weight: normal !important;
  color: #000000 !important;
} */

.stockProdSearchIpt,
.stockProdSearchIpt:hover,
.stockProdSearchIpt:focus {
  /* background: #E2E8EC; */
  border: 1px solid #dfdfdf !important;
  border-radius: 3px;
  box-shadow: none;
  opacity: 1;
}

.stockOrderCard {
  height: calc(0.5625 * 79vw) !important;
  height: calc(var(--aspect-ratio) * 79vw) !important;
}

.omsOrderCardsScroll {
  height: calc(0.5625 * 64vw) !important;
  height: calc(var(--aspect-ratio) * 64vw) !important;
}

.stockOrdersScroll {
  height: calc(0.5625 * 52vw) !important;
  height: calc(var(--aspect-ratio) * 52vw) !important;
}

.addProdStockOrdersScroll {
  height: calc(0.5625 * 45vw) !important;
  height: calc(var(--aspect-ratio) * 45vw) !important;
}

.topSlowProdsScroll {
  height: calc(0.5625 * 32vw) !important;
  height: calc(var(--aspect-ratio) * 32vw) !important;
}

.orderDetailsTableScroll {
  height: calc(0.5625 * 34vw) !important;
  height: calc(var(--aspect-ratio) * 34vw) !important;
}

.addProductCodeInput:hover,
.addProductCodeInput:focus {
  border-color: #d9d9d9;
}

.customerSearchInput {
  font-size: 1vw;
}

.customerSearchInput:hover,
.customerSearchInput:focus {
  border: none;
}

.customerSearchInput .ant-input::-webkit-input-placeholder {
  color: #929098 !important;
  font-size: 1em !important;
  margin-left: -1.5vw;
  font-weight: 500;
  font-family: Inter;
}

.customerSearchInput .ant-input:-ms-input-placeholder {
  color: #929098 !important;
  font-size: 1em !important;
  margin-left: -1.5vw;
  font-weight: 500;
  font-family: Inter;
}

.customerSearchInput .ant-input::placeholder {
  color: #929098 !important;
  font-size: 1em !important;
  margin-left: -1.5vw;
  font-weight: 500;
  font-family: Inter;
}

/* .customerSearchInput{
  font-size: 1vw;
} 

.customerSearchInput .ant-input,
.customerSearchInput .ant-input::placeholder {
  font-size: 0.5em !important; 
  font-weight: 500; 
  font-family: Inter;
  margin-left: -1vw;
  color: #929098;
} */

.customerDetailsInput.ant-input::-webkit-input-placeholder {
  color: #929098 !important;
  /* letter-spacing: 0.08vh !important; */
  font-size: 0.8em !important;
  margin-left: -1.5vw;
  font-weight: 400;
  font-family: Inter;
}

.customerDetailsInput.ant-input:-ms-input-placeholder {
  color: #929098 !important;
  /* letter-spacing: 0.08vh !important; */
  font-size: 0.8em !important;
  margin-left: -1.5vw;
  font-weight: 400;
  font-family: Inter;
}

.customerDetailsInput.ant-input::placeholder {
  color: #929098 !important;
  /* letter-spacing: 0.08vh !important; */
  font-size: 0.8em !important;
  margin-left: -1.5vw;
  font-weight: 400;
  font-family: Inter;
}

.customerDetailsInput {
  width: "35vw";
  /* font-size: 1.1em !important; */
}

.ant-btn-dashed:hover,
.ant-btn-dashed:focus,
.add-payment-btn-white:hover {
  border-color: #ffa500 !important;
  color: #ffa500 !important;
}

.purchaseGrnScroll {
  height: calc(0.5625 * 48vw) !important;
  height: calc(var(--aspect-ratio) * 48vw) !important;
}

.paymentScroll {
  min-height: 15vh !important;
  max-height: calc(0.5625 * 48vw) !important;
  max-height: calc(var(--aspect-ratio) * 48vw) !important;
}

.supplierSelect:hover,
.supplierSelect:focus {
  border-radius: 5px;
  box-shadow: none;
  opacity: 1;
}

.skuInput:hover,
.skuInput:focus {
  border: 1px solid #ebeded !important;
  border-radius: 5px;
  box-shadow: none;
  opacity: 1;
}

.notificationCnfmBtn,
.notificationCnfmBtn:hover,
.notificationCnfmBtn:focus {
  box-shadow: none;
  background-color: #20b883;
  color: #ffffff;
  border: 1px solid #20b883;
}

.addWastageProdScroll {
  height: calc(0.5625 * 50vw) !important;
  height: calc(var(--aspect-ratio) * 50vw) !important;
}

/* New OrderType and Customer Selection Changes */

.orderTypeSelectionCard {
  background-color: #607d8b;
  border: 1px solid #607d8b;
  cursor: pointer;
}

.labelB {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0.32px;
}

.addCustomerText {
  margin-bottom: 0px;
  color: #000000;
  font-size: 12px;
  letter-spacing: 0px;
}

.salesRepCard {
  background-color: #e2e8ec;
  border-radius: 5px;
  cursor: pointer;
}

.saleRepName {
  margin-bottom: 0px;
  color: #000000;
  font-size: 12px;
  letter-spacing: 0px;
}

.addCustIcon {
  width: calc(0.5625 * 3vw);
  width: calc(var(--aspect-ratio) * 3vw);
  height: calc(0.5625 * 3vw);
  height: calc(var(--aspect-ratio) * 3vw);
}

.salesRepoIcon {
  width: calc(0.5625 * 2vw);
  width: calc(var(--aspect-ratio) * 2vw);
  height: calc(0.5625 * 2vw);
  height: calc(var(--aspect-ratio) * 2vw);
  vertical-align: sub;
}

.salesRepClose {
  font-size: 10px;
  vertical-align: baseline;
}

.salesRepresentativeCard {
  width: 100%;
  background: #ebeded;
  border: 1px solid #ebeded;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  cursor: pointer;
}

.representativeName {
  margin-bottom: 0px;
  color: #000000;
  font-size: 12px;
  letter-spacing: 0px;
  text-align: center;
}

.addCustomerTextRest {
  margin-bottom: 0px;
  color: #51ad9a;
  font-size: 10px;
  letter-spacing: 0px;
}

.optionsBtnsfb {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.customerCardScroll {
  height: calc(0.5625 * 61vh) !important;
  height: calc(var(--aspect-ratio) * 61vh) !important;
  overflow-x: hidden !important;
}

.tableRowSelectedBlue {
  background-color: #f3f4f9;
}
/*
tr.tableRowSelectedBlue {
  background-color: #e6f7ff !important;
} */

.tableSelectedBlue {
  background-color: #fff;
  border-bottom: none !important;
}

tr.tableSelectedBlue {
  background-color: #fff !important;
  border-bottom: none !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: rgba(230, 247, 255, 0.3) !important;
}

.tableRowNonSelectedWhite {
  /* background-color: #fff; */
  background-color: var(--table-bg-color) !important;
}

.tableNonSelectedWhite {
  /* background-color: #fff; */
  background-color: var(--table-bg-color) !important;
}

/* Bill Management Screen in F&B Pos */

.managementMainDiv {
  /* height: 100vh; */
  /* padding: 30px 40px; */
  /* padding: 10px 30px 20px; */
  padding: 20px 30px 90px;
  background-color: #f5f5f5;
}

/* .mngBtns {
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #51AD9A;
  border: 1px solid #51AD9A;
  background-color: red;
} */

.mngBtns,
.mngBtns:hover,
.mngBtns:focus {
  height: 60%;
  /* width: 70%; */
  width: 100%;
  padding: 0px 4%;
  margin-bottom: 10px;
  border: 1px solid #51ad9a;
  background-color: #ddebe8;
  border-radius: 5px;
  font-size: 12px;
  text-align: center;
}

.mngBtnText {
  /* font-size: 12px; */
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.28px;
  color: #51ad9a;
  white-space: normal;
  /* overflow: hidden; */
}

.mngBtnIcon {
  /* width: 13%;
  padding-left: 10px; */
  /* width: 15%; */
  padding-left: 10px;
  vertical-align: text-bottom;
}

.enter-receipt {
  margin-bottom: 5px;
  font-size: 20px;
  letter-spacing: 0px;
  color: #2f3856;
}

.zeroBtn {
  height: calc(0.5625 * 10.5vw) !important;
  height: calc(var(--aspect-ratio) * 10.5vw) !important;
}

.search-results {
  font-size: 12px;
  letter-spacing: 0.32px;
  color: #b9b9b9;
}

.receipt-num {
  font-size: 12px;
  letter-spacing: 0.32px;
  color: #000000;
  cursor: pointer;
}

.refundModalTitle {
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #51ad9a;
  text-align: center;
}

.refundBtns {
  padding-bottom: 10px;
  justify-content: center;
}

.refundNoBtn,
.refundNoBtn:hover,
.refundNoBtn:focus {
  margin-left: 20px;
  width: 20%;
  color: #000000;
  border-radius: 3px;
  background: #e5e5e5;
  border: none;
}

.refundYesBtn,
.refundYesBtn:hover,
.refundYesBtn:focus {
  width: 20%;
  color: #000000;
  border-radius: 3px;
  background: #e5e5e5;
  border: none;
}

.refund-remarks {
  margin-bottom: 30px;
  padding: 10px;
  border: 1px solid #ebeded;
  border-radius: 5px;
  resize: none;
}

.refund-remarks::-webkit-input-placeholder {
  font-size: 12px;
  letter-spacing: 0.32px;
  color: #b9b9b9;
}

.refund-remarks:-ms-input-placeholder {
  font-size: 12px;
  letter-spacing: 0.32px;
  color: #b9b9b9;
}

.refund-remarks::placeholder {
  font-size: 12px;
  letter-spacing: 0.32px;
  color: #b9b9b9;
}

.customerSelect,
.customerSelect:hover,
.customerSelect:focus {
  border: 1px solid #ebeded !important;
  border-radius: 5px;
  box-shadow: none;
  opacity: 1;
}

.focusDashboardCard:focus-visible,
.focusDashboardCard:focus {
  outline: #0d4da9 auto 1px !important;
}

.focusButtonsTill:focus-visible,
.focusButtonsTill:focus {
  outline: black auto 4px !important;
}

.prodCardNoImage {
  height: 100%;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #c0c1c5;
}

.prodCardNoImage-sm {
  height: 100%;
  background: #fff;
  border-radius: 5px;
  color: #0f0718;
  border: 1px solid #c0c1c5;
  height: 8vh;
}

.productPriceNoImg {
  margin-bottom: 0px;
  font-weight: normal;
  font-size: 0.9vw;
  letter-spacing: 0.26px;
  color: #666666;
}

.productPriceNoImg-sm {
  margin-bottom: 0px;
  font-weight: normal;
  font-size: 1.1vw;
  letter-spacing: 0.26px;
  color: #046307;
  text-align: right;
  font-family: 500;
}

.prodNameNoImg {
  margin-bottom: 0px;
  font-size: 1.1vw;
  color: #000000;
  font-weight: normal;
  letter-spacing: 0.28px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.prodNameNoImg-sm {
  margin-bottom: 0px;
  font-size: 1.1vw;
  color: #0f0718;
  font-weight: normal;
  letter-spacing: 0.28px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  font-weight: 550;
}

/* Keyboard Shortcuts Modal Starts */

.shortcutsModalTitle {
  margin-bottom: 0px;
  font-size: 18px !important;
  font-weight: normal !important;
  letter-spacing: 0px;
  color: #000000;
}

.screenTitle {
  margin-bottom: 5px;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
}

.shortcutsScroll {
  height: 72vh !important;
}

.shortcutUl {
  padding-left: 0px;
  list-style-type: none;
}

.shortcutList {
  margin-bottom: 10px;
}

.shortcutBtn,
.shortcutBtn:hover,
.shortcutBtn:focus {
  font-size: 12px;
  border: 1px solid #00000029;
  box-shadow: 0px 3px 0px #00000029;
  border-radius: 5px;
  color: #000000;
  text-align: center;
}

.shortcutForScrenName {
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
}

/* Keyboard Shortcuts Modal Ends */

.importBtn,
.importBtn:hover,
.importBtn:focus {
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9 !important;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%) !important;
}

.priceUpdateScroll {
  height: calc(0.5625 * 51vw) !important;
  height: calc(var(--aspect-ratio) * 51vw) !important;
}

.add-batch-card {
  height: 66vh;
  box-shadow: 0px 2px 4px 3px #f0f0f0;
  border-radius: 8px;
  border: 1px solid #ebeded;
}

.add-batches-scroll {
  height: calc(0.5625 * 45vw) !important;
  height: calc(var(--aspect-ratio) * 45vw) !important;
}

.add-batch,
.add-batch:hover,
.add-batch:focus {
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  background: #607d8b;
  border: 1px solid #607d8b;
  border-radius: 5px;
}

.add-batch-disable,
.add-batch-disable:hover,
.add-batch-disable:focus {
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  background: #bfcad0;
  border: 1px solid #bfcad0;
  border-radius: 5px;
}

.add-plus {
  font-size: 14px;
  letter-spacing: 0px;
  color: #0c4da9;
  cursor: pointer;
}

.add-batch-details-input,
.add-batch-details-input:hover,
.add-batch-details-input:focus {
  width: 70%;
  padding: 5px 10px;
  background-color: #f7f7f7;
  font-weight: normal;
  text-align: center;
  border: 1px solid #ebeded;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
}

.submitBtn,
.submitBtn:hover,
.submitBtn:focus {
  width: 100%;
  background: #20b883;
  border: 1px solid #20b883;
  border-radius: 5px;
  font-size: 14px;
  letter-spacing: 0.32px;
  color: #ffffff;
}

.cancelBtn,
.cancelBtn:hover,
.cancelBtn:focus {
  width: 100%;
  background: #e5e5e5;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  font-size: 14px;
  letter-spacing: 0.32px;
  color: #000000;
}

.parkedBillBtn {
  padding: 11px 24px;
  background-color: #ddebe8;
  border: 1px solid #51ad9a;
  border-radius: 6px;
}

/* Responsive Changes */

.header {
  padding: 0 0 0.3%;
  background-color: #0c4da9;
}

.posMenuIcon {
  width: 27%;
}

.posBunit {
  font-weight: normal;
  font-size: 1.2vw;
  letter-spacing: 0.36px;
  color: #ffffff;
  opacity: 1;
}

.timeFmt {
  margin-left: 2%;
  font-weight: normal;
  font-size: 1.2vw;
  letter-spacing: 0.36px;
  color: #ffffff;
  opacity: 1;
}

.time {
  color: #ffffff;
  font-weight: normal;
  font-size: 1.2vw;
  letter-spacing: 0.36px;
  opacity: 1;
}

.posLogoImg {
  width: 60%;
}

.tickIcon {
  width: 40px;
}

@media only screen and (min-width: 1024px) and (max-width: 1138px) {
  .mainRow {
    height: calc(0.5625 * 106vw) !important;
    height: calc(var(--aspect-ratio) * 106vw) !important;
  }

  .productScroll2 {
    height: calc(0.5625 * 86.5vw) !important;
    height: calc(var(--aspect-ratio) * 86.5vw) !important;
  }

  .main {
    width: 100%;
    --size: calc(var(--aspect-ratio) * 113.6vw);
    height: calc(var(--size) - 16px);
  }

  .tablesScroll {
    height: calc(0.5625 * 85.5vw) !important;
    height: calc(var(--aspect-ratio) * 85.5vw) !important;
  }

  .productCartScroll {
    height: calc(0.5625 * 61vw) !important;
    height: calc(var(--aspect-ratio) * 61vw) !important;
  }
}

@media only screen and (min-width: 1139px) and (max-width: 1200px) {
  .mainRow {
    height: calc(0.5625 * 98.5vw) !important;
    height: calc(var(--aspect-ratio) * 98.5vw) !important;
  }

  .productScroll2 {
    height: calc(0.5625 * 77.5vw) !important;
    height: calc(var(--aspect-ratio) * 77.5vw) !important;
  }

  .main {
    width: 100%;
    --size: calc(var(--aspect-ratio) * 106vw) !important;
    height: calc(var(--size) - 16px);
  }

  .tablesScroll {
    height: calc(0.5625 * 79.5vw) !important;
    height: calc(var(--aspect-ratio) * 79.5vw) !important;
  }

  .productCartScroll {
    height: calc(0.5625 * 58vw) !important;
    height: calc(var(--aspect-ratio) * 58vw) !important;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1300px) {
  .mainRow {
    height: calc(0.5625 * 79.5vw) !important;
    height: calc(var(--aspect-ratio) * 79.5vw) !important;
  }

  .productScroll2 {
    height: calc(0.5625 * 60vw) !important;
    height: calc(var(--aspect-ratio) * 60vw) !important;
  }

  .main {
    width: 100%;
    --size: calc(var(--aspect-ratio) * 86vw) !important;
    height: calc(var(--size) - 16px);
  }
}

@media only screen and (min-width: 1301px) and (max-width: 1400px) {
  .mainRow {
    height: calc(0.5625 * 91.87vw) !important;
    height: calc(var(--aspect-ratio) * 91.87vw) !important;
  }

  .productScroll2 {
    height: calc(0.5625 * 73vw) !important;
    height: calc(var(--aspect-ratio) * 73vw) !important;
  }

  .productCartScroll {
    height: calc(0.5625 * 53vw) !important;
    height: calc(var(--aspect-ratio) * 53vw) !important;
  }

  .main {
    width: 100%;
    --size: calc(var(--aspect-ratio) * 99.1vw) !important;
    height: calc(var(--size) - 16px);
  }

  .tablesScroll {
    height: calc(0.5625 * 75.5vw) !important;
    height: calc(var(--aspect-ratio) * 75.5vw) !important;
  }
}

@media only screen and (min-width: 1401px) {
  .mainRow {
    height: calc(0.5625 * 93.87vw) !important;
    height: calc(var(--aspect-ratio) * 93.87vw) !important;
  }

  .productScroll2 {
    height: calc(0.5625 * 73vw) !important;
    height: calc(var(--aspect-ratio) * 73vw) !important;
  }

  .productCartScroll {
    height: calc(0.5625 * 53vw) !important;
    height: calc(var(--aspect-ratio) * 53vw) !important;
  }

  .main {
    width: 100%;
    --size: calc(var(--aspect-ratio) * 101vw) !important;
    height: calc(var(--size) - 16px);
  }

  .tablesScroll {
    height: calc(0.5625 * 77.5vw) !important;
    height: calc(var(--aspect-ratio) * 77.5vw) !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1025px) {
  .mainRow {
    height: calc(0.5625 * 127.7vw) !important;
    height: calc(var(--aspect-ratio) * 127.7vw) !important;
  }

  .productScroll2 {
    height: calc(0.5625 * 105.5vw) !important;
    height: calc(var(--aspect-ratio) * 105.5vw) !important;
  }

  .main {
    width: 100%;
    --size: calc(var(--aspect-ratio) * 135vw) !important;
    height: calc(var(--size) - 16px);
  }

  .tablesScroll {
    height: calc(0.5625 * 105vw) !important;
    height: calc(var(--aspect-ratio) * 105vw) !important;
  }

  .productCartScroll {
    height: calc(0.5625 * 75vw) !important;
    height: calc(var(--aspect-ratio) * 75vw) !important;
  }

  .leftCard,
  .rightCard {
    height: 94vh;
  }

  .header {
    padding: 0 0 0.5%;
    background-color: #0c4da9;
  }

  .posMenuIcon {
    width: 30%;
  }

  .posBunit {
    font-size: 1.6vw;
  }

  .timeFmt {
    font-size: 1.6vw;
  }

  .time {
    font-size: 1.6vw;
  }

  .posLogoImg {
    width: 80%;
  }

  /* .leftCard,
  .rightCard {
    height: calc(var(--aspect-ratio) * 79vw);
  } */

  .addCustomerText {
    font-size: 13px;
  }

  .table-stl {
    /* height: calc(var(--aspect-ratio) * 60vw); */
    height: 83vh !important;
  }

  .productScroll {
    height: 81vh !important;
  }

  .productsFilterBtn {
    padding: 0px;
  }

  .qty-card {
    height: 10vh;
    padding-top: 10px;
  }

  .prodQtyCard {
    padding-top: 50px !important;
    height: 50vh;
  }

  .offersCard {
    height: 50vh;
  }

  .infoCardList {
    height: 42vh !important;
  }

  .downIcon {
    height: 3vh;
    width: 3vh;
  }

  /* .taxDisLbl {
    font-size: 13px !important;
    font-weight: 500;
  } */

  /* .totalBtn {
    height: 6vh;
  } */

  .totalLabel {
    font-size: 18px !important;
    font-weight: 600 !important;
  }

  .backArrowSearchProducts {
    font-size: 24px !important;
  }

  .productName {
    font-size: 1vw !important;
  }

  .add-product-save,
  .add-product-pay {
    height: 7vh;
    font-size: 19px !important;
  }

  .skuUpcText {
    font-size: 12px !important;
  }

  .productDetails {
    font-size: 18px !important;
  }

  .prodNameInfo,
  .priceNameInfo {
    font-size: 14px !important;
    font-family: Inter;
  }

  .prod-desc-lbl {
    font-size: 13px !important;
  }

  .productInfoCard {
    height: 20vh;
  }

  .qtyBtnClose {
    width: 15px !important;
    height: 15px !important;
  }

  .backSpaceIcon {
    width: 25px !important;
    height: 16px !important;
  }

  .tickIcon {
    width: 20px;
  }

  .productInfoTabsHeading {
    font-size: 18px !important;
  }

  .qtySales {
    font-size: 14px !important;
  }

  .qtySalesValue {
    font-size: 18px !important;
  }

  .stockTitles {
    font-size: 13px !important;
  }

  .salesPercentValues {
    font-size: 14px !important;
  }

  .prodDescInfo {
    font-size: 14px !important;
  }
}

.notes-submitBtn,
.notes-submitBtn:hover,
.notes-submitBtn:focus {
  background-color: #51ad9a;
  border: 1px solid #51ad9a;
  border-radius: 3px;
  color: #ffffff;
}

.notes-cancelBtn,
.notes-cancelBtn:hover,
.notes-cancelBtn:focus {
  border: 1px solid #d9d9d9;
  color: #000000;
  outline: none;
}

/* MY styles */
.cartMenuItems {
  width: 100%;
  color: #ffffff;
  display: flex;
  height: 4.2vw;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
}

ul {
  list-style: none;
  padding-left: 0;
}

/* li:before {
  content: "\2022";
  margin-right: 5px;
} */

.ant-modal-footer {
  border-top: none;
}

.ant-modal-header {
  border-bottom: none;
  background: #f3f4f9;
}

.introjs-tooltip {
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  background-color: #fff;
  min-width: 250px;
  max-width: 350px !important;
  width: -webkit-max-content;
  width: max-content;
  border-radius: 5px;
  box-shadow: 0 3px 30px rgb(33 33 33 / 30%);
  transition: opacity 0.1s ease-out;
}

.introjs-nextbutton {
  box-sizing: content-box;
  position: relative;
  overflow: visible;
  padding: 0.5rem 1rem;
  border: none !important;
  text-decoration: none;
  /* text-shadow: 1px 1px 0 #fff; */
  font-size: 14px;
  font-weight: 700;
  color: #ffffff !important;
  white-space: nowrap;
  cursor: pointer;
  outline: 0;
  background-color: #0c173a !important;
  border-radius: 0.2em;
  zoom: 1;
  display: inline;
}

.introjs-button {
  box-sizing: content-box;
  position: relative;
  overflow: visible;
  padding: 0.5rem 1rem;
  /* border: 1px solid #bdbdbd; */
  text-decoration: none;
  text-shadow: none !important;
  font-size: 14px;
  color: #424242;
  white-space: nowrap;
  cursor: pointer;
  outline: 0;
  background-color: #f4f4f4;
  border-radius: 0.2em;
  zoom: 1;
  display: inline;
}

.introjs-prevbutton {
  background: #ffffff !important;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 16px !important;

  color: #0c173a !important;
}

.introjs-bullets ul li a {
  border-radius: 50%;
  width: 9px !important;
  height: 9px;
  margin: 0 2px;
}

.introjs-bullets ul li:before {
  content: none !important;
  margin-right: 0 !important;
}

.introjs-bullets ul li a.active {
  background-color: #88b906 !important;
  border-radius: 50%;
  width: 9px !important;
  height: 9px;
  margin: 0 2px;
}

.bullets ul li a:hover {
  /* width: ; */
  background: #999;
  text-decoration: none;
  outline: 0;
}

.introjs-nextbutton {
  float: unset;
  margin-left: 10px;
}

.introjs-prevbutton {
  float: unset;
}

.introjs-tooltipbuttons {
  border-top: 1px solid #e0e0e0;
  padding: 20px;
  text-align: right;
  white-space: nowrap;
  background: #ffffff;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.introjs-helperLayer {
  box-shadow: rgb(33 33 33 / 80%) 0px 0px 0px 0px, rgb(33 33 33 / 50%) 0px 0px 0px 5000px !important;
  border: none !important;
}

.remove_btn {
  padding-left: 0.5vw;
  border-left: 1px solid black;
}

.totalInput.ant-input::-webkit-input-placeholder {
  color: #929098 !important;
  /* letter-spacing: 0.08vh !important; */
  font-size: 1.2vw;
  margin-right: 0.4vw;
  font-weight: 400;
  font-family: Inter;
}

.totalInput.ant-input:-ms-input-placeholder {
  color: #929098 !important;
  /* letter-spacing: 0.08vh !important; */
  font-size: 1.2vw;
  margin-right: 0.4vw;
  font-weight: 400;
  font-family: Inter;
}

.totalInput.ant-input::placeholder {
  color: #929098 !important;
  /* letter-spacing: 0.08vh !important; */
  font-size: 1.2vw;
  margin-right: 0.4vw;
  font-weight: 400;
  font-family: Inter;
}

.totalQtyInput.ant-input::-webkit-input-placeholder {
  color: #929098 !important;
  /* letter-spacing: 0.08vh !important; */
  font-size: 1.2vw;
  margin-right: 0.4vw;
  font-weight: 400;
  font-family: Inter;
  vertical-align: middle !important;
}

.totalQtyInput.ant-input:-ms-input-placeholder {
  color: #929098 !important;
  /* letter-spacing: 0.08vh !important; */
  font-size: 1.2vw;
  margin-right: 0.4vw;
  font-weight: 400;
  font-family: Inter;
  vertical-align: middle !important;
}

.totalQtyInput.ant-input::placeholder {
  color: #929098 !important;
  /* letter-spacing: 0.08vh !important; */
  font-size: 1.2vw;
  margin-right: 0.4vw;
  font-weight: 400;
  font-family: Inter;
  vertical-align: middle !important;
}

.voucher .ant-modal-header {
  border-bottom: none;
  background: #f3f4f9;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%) !important;
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%) !important;
}

.custom-dropdown {
  height: 18vh;
  width: 20;
}

.genderSelect > .ant-select-selector {
  height: 7.2vh !important;
  width: 37.2vw !important;
  font-size: 2vh;
}

/* Cash Management Keypad Supermarket */
.amt-dial-btn-super-market-cash-management {
  width: 8vw;
  height: 10vh;
  border-radius: 5px;
  background: #92909880;
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: 1px solid #fff;
  /* font-size: 1.2vw; */
  font-size: 1.5em;
  font-weight: 500;
  color: #0f0718 !important;
}
.amt-dial-btn-super-market-cash-management-cancel-discount {
  width: 8vw;
  height: 10vh;
  border-radius: 5px;
  background: #92909880;
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: 1px solid #fff;
  /* font-size: 1.2vw; */
  font-size: 1.5em;
  font-weight: 500;
  color: #0f0718 !important;
}
.amt-dial-btn-market-cash-management-discount {
  width: 97%;
  height: 21.2vh;
  border-radius: 5px;
  background: #92909880;
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: 1px solid #fff;
  /* font-size: 1.2vw; */
  font-size: 1.5em;
  font-weight: 500;
  color: #0f0718;
  cursor: pointer;
}

.productionScroll {
  height: 62vh !important;
}

.focused-row {
  background-color: #e0e0e0;
}

.popover-trigger-button:focus {
  outline: none;
  border: 2px solid #40a9ff;
  box-shadow: 0 0 5px #40a9ff;
  border-radius: 4px;
}

.shortcutBtn1,
.shortcutBtn1:hover,
.shortcutBtn1:focus {
  font-size: 12px;
  border: 1px solid #00000029;
  box-shadow: 0px 3px 0px #00000029;
  border-radius: 5px;
  color: #000000;
  text-align: center;
  margin-right: 10px;
}

.shortcutTable .selectedRow {
  background-color: #e0e0e0;
}

/* globalStyles */

:root {
  --aspect-ratio: 0.5625;
}

.ant-table-selection-col,
.ant-table-selection-column {
  visibility: hidden;
}

.ant-modal-mask {
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  background-color: #000000cc;
}

.ant-input-clear-icon-hidden {
  visibility: visible;
}

.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  /* height: 5vh !important; */
  padding: 2%;
  background: #e2e8ec !important;
  font-size: 18px;
  font-weight: 600;
}

.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  background: #e2e8ec !important;
  border-radius: 5px 0 0 5px !important;
}

.ant-input-group-addon {
  width: 55px !important;
  border: 1px solid #cfd9e0 !important;
  border-radius: 5px !important;
  font-size: 1.5vw !important;
}

/* .ant-input-group-addon:first-child {
  background-color: #BAC2C6;
  color: #fff;
}

.ant-input-group-addon:last-child {
  background-color: #76B769;
  color: #fff;
} */

.ant-btn-icon-only.ant-btn-lg > * {
  font-size: 1.5vw;
  padding-top: 5px;
  color: #000000;
}

/* Selling Products Table */
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  position: absolute;
  top: 50%;
  right: 0;
  width: 0px !important;
  height: 1.6em;
  background-color: rgba(0, 0, 0, 0.06);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  transition: background-color 0.3s;
  content: "";
}

.ant-table-thead > tr > th {
  padding: 1vw !important;
  font-size: 0.9vw;
  font-weight: 400;
  color: rgba(52, 40, 44, 0.77);
  background: #ffffff;
}

.ant-table-tbody > tr:hover {
  background: none;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #ebeded;
}

.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #ebeded;
}

.ant-table-tbody > tr > td {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.11) !important;
}

/* Ends */

/* Product Category List Menu */

.ant-menu-item,
.ant-menu-item-only-child {
  color: #2f3856;
  /* font-size: 1.1vw; */
  padding-left: 25px !important;
}

.ant-menu-item-only-child:hover {
  color: #2f3856;
}

.ant-menu-item-selected {
  color: #374957;
  font-weight: 600;
}

.ant-menu-item-selected:hover {
  color: #374957;
  font-weight: 600;
}

.ant-menu-submenu-title,
.ant-menu-submenu-title:hover {
  color: #000000;
  font-size: 1.2vw;
}

.ant-menu-submenu-arrow,
.ant-menu-submenu-arrow:hover {
  color: #2f3856 !important;
  font-weight: 600;
  font-size: 1.1vw;
}

.ant-menu-inline .ant-menu-item {
  margin-top: 0;
}

.ant-menu-sub {
  max-height: calc(0.5625 * 68vw);
  max-height: calc(var(--aspect-ratio) * 68vw);
  min-height: calc(0.5625 * 68vw);
  min-height: calc(var(--aspect-ratio) * 68vw);
  overflow-x: hidden;
  overflow-y: auto;
}

.ant-menu-sub::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.ant-menu-submenu-arrow,
.ant-menu-submenu-arrow:hover {
  color: #2f3856 !important;
  font-weight: 600;
  font-size: 1.3vw;
  top: 53%;
}

/* Ends */

/* Order History Tabs */

.ant-input-prefix {
  margin-right: 20px;
}

.ant-tabs-tab {
  margin: 0 0 0 90px;
  padding: 12px 0 5px 0;
  font-size: 1vw;
  font-weight: 600;
}

.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0px 0 90px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab:hover {
  color: #000000;
  font-weight: 600;
}

/* .ant-tabs-ink-bar,
.ant-tabs-ink-bar-animated {
  width: 100%;
  height: 3px;
  border-radius: 10px;
  background: #2F3856;
} */

.ant-tabs-ink-bar,
.ant-tabs-ink-bar-animated {
  /* width: 60px !important; */
  height: 3px !important;
  /* margin-left: -12px !important; */
  border-radius: 10px;
  background: #2f3856;
}

/* Ends */

/* Returns Modal */

.ant-modal-title {
  margin: 0;
  color: #2f3856;
  font-weight: 600;
  font-size: 1.1vw;
}

.ant-modal-close-x {
  font-size: 1.3vw;
  /* color: #eb3a4c; */
}

/* Ends */

/* Attributes Select Box */

/* .ant-select-item {
  padding: 16px;
  font-size: 1.1vw !important;
  font-weight: 500 !important;
} */

.ant-select-selector:hover,
.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-select-selector {
  /* height: 7vh !important; */
  border-radius: 3px !important;
}

.attribute .ant-select-selector {
  border: 1px solid #d9d9d9;
}

/* .ant-select-selection-item {
  font-size: 1.3vw !important;
  color: #000000 !important;
  margin-top: 0.5vw;
}

.ant-select-arrow {
  top: 25px;
  right: 15px;
  font-size: 1.3vw;
  color: #000000;
} */

.ant-radio {
  vertical-align: middle;
}

/* Ends */

/* .ant-modal {
  top: 75px;
} */

.ant-tabs-centered > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*="ant-tabs-nav-wrap-ping"]),
.ant-tabs-centered > div > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*="ant-tabs-nav-wrap-ping"]) {
  justify-content: flex-start;
}

.ant-card-head {
  padding: 0 10px;
}

.ant-card-head-title {
  padding: 12px 0px !important;
}

/* .ant-ribbon {
  background-color: #02C437;
} */

.ant-ribbon.ant-ribbon-placement-start {
  left: 0px;
}

.ant-ribbon-corner {
  border: none;
}

.ant-ribbon {
  content: "";
  font-size: 10px !important;
  line-height: 4px !important;
  border-left: 0px solid transparent;
  border-right: 5px solid #fff;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner:hover {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #000;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: " ";
}

.ant-radio-checked .ant-radio-inner,
.ant-radio-checked .ant-radio-inner:hover {
  border-color: #000000 !important;
}

.ant-radio-inner::after {
  background-color: #000000;
}

.ant-radio-checked::after {
  border: 1px solid #000000;
}

.ant-table-small .ant-table-thead > tr > th {
  background-color: #e0e7ef;
}

@media only screen and (max-width: 1024px) {
  /* .ant-menu-sub {
    max-height: 43vw;
    min-height: 43vw;
    overflow-x: hidden;
    overflow-y: auto;
  } */

  .ant-tabs-tab {
    margin: 0 0 0 100px;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 100px;
  }

  /* .ant-select-selection-item {
    margin-top: 0.6vw;
  } */

  .ant-menu-sub {
    max-height: 80vh;
    min-height: 80vh;
  }

  .ant-table-thead > tr > th {
    font-size: 13px;
    padding: 10px !important;
  }

  .ant-table-tbody > tr > td {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1920px) {
  .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
    padding: 17px;
    background: #e2e8ec !important;
    font-size: 18px;
    font-weight: 600;
  }

  .ant-menu-sub {
    max-height: calc(0.5625 * 72vw);
    max-height: calc(var(--aspect-ratio) * 72vw);
    min-height: calc(0.5625 * 72vw);
    min-height: calc(var(--aspect-ratio) * 72vw);
  }

  .ant-input-group-addon {
    width: 80px !important;
  }

  .ant-select-selection-item {
    margin-top: 0.8vw;
    font-size: 1.1vw !important;
  }
}

.ant-badge-status-text {
  font-size: 12px;
}

/* Menu Drawer Styles */

.ant-drawer-header {
  background: #2f3856;
  border-bottom: 1px solid #2f3856;
}

.ant-drawer-title {
  color: #ffffff;
  font-weight: 600;
}

.ant-drawer-close,
.ant-drawer-close:hover {
  padding: 16px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-bottom: 0;
  margin-left: 8px;
  background-color: #2f3856 !important;
}

:root {
  --aspect-ratio: 0.5625;
}

.posIcon {
  font-size: 0.8em;
  font-weight: 500;
  letter-spacing: 0.05vh;
  padding-top: 4px;
  font-family: Inter;
  line-height: 1em;
}

.payment {
  font-size: 0.95em;
  font-weight: 500;
  letter-spacing: 0.05vh;
  font-family: Inter;
  line-height: 1.2em;
}

.ant-drawer-body {
  overflow: hidden;
}

.productName1 {
  font-size: 0.9vw !important;
  font-weight: 500;
  letter-spacing: 0.05vh;
  font-family: Inter;
}

.tableRow {
  font-weight: 400;
  font-family: Inter;
  font-size: 1em;
}

.posMenuIcons {
  font-size: 0.8em;
  letter-spacing: 0.06vh;
  font-weight: 500;
  padding-top: 7px;
  font-family: Inter;
}

.posMenuTill {
  font-size: 0.8em;
  letter-spacing: 0.06vh;
  font-weight: 500;
  padding-top: 7px;
  font-family: Inter;
  width: 5vw;
}

.posCategory {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  cursor: pointer;
}

.productDescription {
  font-size: 0.86vw;
  color: #929098;
  margin-top: -0.5vw;
  font-weight: 500;
  letter-spacing: 0.03vw;
  line-height: 0.9;
  font-family: Inter;
}

.productDescription-sm {
  font-size: 0.86vw;
  color: #929098;
  font-weight: 500;
  letter-spacing: 0.03vw;
  line-height: 1.1;
  font-family: Inter;
  padding: 1% 0;
}

.productDescription-kiosk {
  font-size: 1.5vh;
  color: #0f0718;
  font-weight: 500;
  letter-spacing: 0.03vw;
  line-height: 1.1;
  font-family: Inter;
  padding: 3% 0;
}

.ant-checkbox-group-item {
  padding-bottom: 8px;
}
.ant-card-body {
  padding: 0;
}
.ant-col-1 {
  flex: 0 0;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 8px 16px;
}
.tillEventsScroll {
  height: 28vh !important;
  width: "0.1vw";
}
.incomingDataScroll {
  height: 70vh !important;
}
.orderListScroll {
  height: 35vh !important;
}
.ordersDataScroll {
  height: 31vh !important;
}

.cartStyle {
  font-weight: 600;
  align-items: end;
  display: flex;
  font-size: 1.57vw;
  padding-left: 1.6%;
  color: #0f0718;
}

.cartSelect {
  font-size: 0.8em;
  /* color: #1e1e1e !important; */
  color: #929098 !important;
  display: flex;
  align-items: center;
  font-weight: 500;
  text-align: left;
}

.cartOption {
  font-size: 0.9em;
  color: #0f0718;
  font-weight: 500;
  text-align: left;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: rgba(146, 144, 152, 0.5) !important;
  color: #1e1e1e;
  font-weight: 500;
}

/* style={{justifyContent:"center",alignItems:"center",display:"flex"}} */

.ant-table-thead > tr > th {
  background: #f3f4f9 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.11);
}

.ant-table {
  background: var(--table-bg-color) !important; /*#f3f4f9*/
}

tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  background: #f3f4f9 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.ant-table-thead > tr > th {
  font-size: 1.2vw !important;
  color: #000 !important;
}

.ant-table-tbody > tr:hover {
  background: #f3f4f9 !important;
}

.table-stl > .ant-table-tbody > tr > td {
  /* border: none; */
  font-size: 1.6vw !important;
}

/* .moreOptions>.ant-select-arrow {
  top: 65%;
} */
.ant-select-selector {
  border: 1px solid #fff !important;
  height: 5vh !important;
}

.supplier .ant-select-selector {
  border: 1px solid #d9d9d9 !important;
  border-radius: 5px !important;
  height: 7vh !important;
  font-weight: 400 !important;
  font-size: 1vw !important;
}

.ant-select {
  font-size: 0.9vw !important;
  font-weight: 600 !important;
  color: #1e1e1e;
}

.moreOptions > .ant-select-dropdown {
  border-radius: 5px !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #f3f4f9 !important;
}

.ant-checkbox-checked::after {
  border: none !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2f3856 !important;
  border-color: #2f3856 !important;
  border-radius: 4px !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #2f3856 !important;
  border-radius: 4px !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #a4cd38 !important;
}

.ant-checkbox-inner {
  border-radius: "3px" !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  top: "6px" !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: white;
  /* Change the border color to white */
}

.inputAdd input::-webkit-input-placeholder {
  font-size: 8px;
  /* Set the desired font size for the placeholder */
}

.inputAdd input:-ms-input-placeholder {
  font-size: 8px;
  /* Set the desired font size for the placeholder */
}

.inputAdd input::placeholder {
  font-size: 8px;
  /* Set the desired font size for the placeholder */
}

/* CART page styles */

.cart-table .ant-table {
  font-weight: 500;
  font-size: 1.1vh;
}

.cart-table .ant-table-thead > tr > th {
  font-size: 0.9vw !important;
  color: #0f0718 !important;
  font-weight: 600;
  background-color: #fff !important;
  padding-top: 1.5vh !important;
  border-bottom: none !important;
  padding-bottom: 0.8vh !important;
}

.cart-table .ant-table-thead > tr > th:nth-child(3) {
  text-align: center !important;
}

.cart-table .ant-table-thead > tr > th:nth-child(2) {
  text-align: center !important;
}

.cart-table .ant-table-tbody > tr > td {
  padding: 1.5vh;
  color: #0f0718 !important;
}

.taxDisLbl {
  font-weight: 600 !important;
}

.selecItem .ant-select-single .ant-select-selection-item {
  font-size: 0.9vw !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  font-weight: 500 !important;
  color: #1e1e1e;
}

.selecItem.ant-select-selector:hover {
  border: none !important;
}

.selecItem .ant-select-selector {
  height: 8vh !important;
}

.selecItem.ant-select-single .ant-select-selector .ant-select-selection-item {
  color: #92909880 !important;
  font-weight: 400;
  font-size: 1em;
  display: flex;
  align-items: center;
}
.selecItem .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  padding-top: 10px;
}

.selecItem {
  width: 10vw;
  height: 8vh;
}

.selectType .ant-select-single .ant-select-selection-item {
  font-size: 0.9vw !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  font-weight: 500 !important;
  color: #1e1e1e;
}

.selectType.ant-select-selector:hover {
  border: none !important;
}

.selectType .ant-select-selector {
  height: 5vh !important;
  width: 17.5vw !important;
}

.selectType.ant-select-single .ant-select-selector .ant-select-selection-item {
  color: #92909880 !important;
  font-weight: 400;
  font-size: 1em;
  display: flex;
  align-items: center;
}
.selectType .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  padding-top: 10px;
}

/* .selectType {
  width: 10vw;
  height: 6vh;
} */

.customerInput .ant-input {
  font-size: 11px !important;
  margin-left: -0.7vw !important;
  color: #000 !important;
  font-weight: 600 !important;
  height: 3.6vh;
}

.customerInput .ant-input::-webkit-input-placeholder {
  color: #929098 !important;
  font-size: 1.05vw;
}

.customerInput .ant-input:-ms-input-placeholder {
  color: #929098 !important;
  font-size: 1.05vw;
}

.customerInput .ant-input::placeholder {
  color: #929098 !important;
  font-size: 1.05vw;
}

.customerInput .ant-input-affix-wrapper > input.ant-input {
  color: #929098 !important;
  letter-spacing: 0.08vh !important;
}

.productSearch.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: none !important;
  border-color: #fff !important;
}

.productSearch-po.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: 1px solid #d9d9d9 !important;
}

.ant-input-affix-wrapper:hover {
  border: 1px solid #fff !important;
}

.productSearch-ks.ant-input-affix-wrapper:hover {
  border: 1px solid #f97316 !important;
}

.productSearch-ks.ant-input-affix-wrapper {
  border: 1px solid #f97316 !important;
}

.listItems {
  list-style: none;
  font-size: 1.5vh;
  padding-top: 2vh;
  font-weight: 500;
  color: #0f0718;
  /* listStyle: 'none', fontSize:"1.2vh",paddingTop:"2vh" */
}

.productSearch .ant-input::-webkit-input-placeholder {
  color: #929098 !important;
  /* letter-spacing: 0.08vh !important; */
  font-size: 1.05vw;
  margin-left: -1.5vw;
  font-weight: 400 !important;
  font-family: Inter;
}

.productSearch .ant-input:-ms-input-placeholder {
  color: #929098 !important;
  /* letter-spacing: 0.08vh !important; */
  font-size: 1.05vw;
  margin-left: -1.5vw;
  font-weight: 400 !important;
  font-family: Inter;
}

.productSearch .ant-input::placeholder {
  color: #929098 !important;
  /* letter-spacing: 0.08vh !important; */
  font-size: 1.05vw;
  margin-left: -1.5vw;
  font-weight: 400 !important;
  font-family: Inter;
}

.productSearch.ant-input-affix-wrapper {
  border: none !important;
}

.productSearch .ant-input-prefix {
  margin-right: 0.8vw;
}

.customer-search .ant-input::-webkit-input-placeholder {
  color: #929098 !important;
  /* letter-spacing: 0.08vh !important; */
  font-size: 1.05vw !important;
  margin-left: -1.5vw;
  font-weight: 550;
  font-family: Inter;
}

.customer-search .ant-input:-ms-input-placeholder {
  color: #929098 !important;
  /* letter-spacing: 0.08vh !important; */
  font-size: 1.05vw !important;
  margin-left: -1.5vw;
  font-weight: 550;
  font-family: Inter;
}

.customer-search .ant-input::placeholder {
  color: #929098 !important;
  /* letter-spacing: 0.08vh !important; */
  font-size: 1.05vw !important;
  margin-left: -1.5vw;
  font-weight: 550;
  font-family: Inter;
}

.customer-search.ant-input-affix-wrapper {
  border: 1px solid #d9d9d9 !important;
}

.customer-search .ant-input-prefix {
  margin-right: 0.8vw;
}

.customer-search .ant-input::-webkit-input-placeholder {
  color: #929098 !important;
  /* letter-spacing: 0.08vh !important; */
  font-size: 1.16vw;
  margin-left: -1.5vw;
  font-weight: 400 !important;
  font-family: Inter;
}

.customer-search .ant-input:-ms-input-placeholder {
  color: #929098 !important;
  /* letter-spacing: 0.08vh !important; */
  font-size: 1.16vw;
  margin-left: -1.5vw;
  font-weight: 400 !important;
  font-family: Inter;
}

.customer-search .ant-input::placeholder {
  color: #929098 !important;
  /* letter-spacing: 0.08vh !important; */
  font-size: 1.16vw;
  margin-left: -1.5vw;
  font-weight: 400 !important;
  font-family: Inter;
}

/* letter-spacing: 0.08vh !important; */
/* opacity: 0.7; */
/* .productSearch-sm .ant-input::placeholder {
  color: #908f96 !important;
  font-size: 1.1vw;
  margin-left: -1.5vw;
  font-weight: 500;
  font-family: Inter; 
} */

.productSearch-sm .ant-input-prefix {
  margin-right: 0.8vw;
}

.productSearch-sm {
  font-size: 1vw;
}

.productSearch-sm .ant-input::-webkit-input-placeholder {
  font-size: 1em !important;
  font-weight: 500 !important;
  font-family: Inter;
  color: #929098;
}

.productSearch-sm .ant-input:-ms-input-placeholder {
  font-size: 1em !important;
  font-weight: 500 !important;
  font-family: Inter;
  color: #929098;
}

.productSearch-sm .ant-input,
.productSearch-sm .ant-input::placeholder {
  font-size: 1em !important;
  font-weight: 500 !important;
  font-family: Inter;
  color: #929098;
}

.productSearch-sm:hover {
  border: 1px solid #ccc !important;
}

.bomSearch-sm .ant-input-prefix {
  margin-right: 0.8vw;
}

.bomSearch-sm {
  font-size: 1vw;
}

.bomSearch-sm .ant-input::-webkit-input-placeholder {
  font-size: 1em !important;
  font-weight: 500 !important;
  font-family: Inter;
  color: #929098;
}

.bomSearch-sm .ant-input:-ms-input-placeholder {
  font-size: 1em !important;
  font-weight: 500 !important;
  font-family: Inter;
  color: #929098;
}

.bomSearch-sm .ant-input,
.bomSearch-sm .ant-input::placeholder {
  font-size: 1em !important;
  font-weight: 500 !important;
  font-family: Inter;
  color: #929098;
}

.bomSearch-sm:hover {
  border: 1px solid #ccc !important;
}

.custom-select .ant-select-selector {
  height: 7.7vh !important;
  /* Set the desired height */
  border: 1px solid #fff !important;
  color: #1e1e1e;
  font-weight: 400;
  font-size: 1em !important;
}

.custom-select.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-top: 2% !important;
}

/* Dicount Modal */

.discount-remove-btn {
  width: 100%;
  font-size: 1.2em;
  border: 1px solid rgba(146, 144, 152, 0.5);
  color: #2f3856;
  border-radius: 4px;
  font-weight: 500;
  padding: 3px;
  height: 7vh;
}

.discount-remove-btn:hover {
  color: #2f3856 !important;
  background-color: #fff !important;
  border-color: #2f3856 !important;
}

.discount-apply-btn {
  width: 100%;
  font-size: 1.2em;
  background-color: #2f3856;
  color: #fff;
  border-radius: 4px;
  font-weight: 500;
  /* letter-spacing: 0.2vh; */
  padding: 4px;
  height: 7vh;
}

.discount-apply-btn:hover {
  background-color: #2f3856 !important;
  color: #fff !important;
  border-color: #2f3856 !important;
}

.discount-value-input .ant-input-affix-wrapper > input.ant-input {
  color: #929098 !important;
  letter-spacing: 0.08vh !important;
}

.discount-value-input.ant-input::-webkit-input-placeholder {
  color: #bfbfbf !important;
  font-family: Inter;
  font-size: 1em !important;
  font-weight: 400;
}

.discount-value-input.ant-input:-ms-input-placeholder {
  color: #bfbfbf !important;
  font-family: Inter;
  font-size: 1em !important;
  font-weight: 400;
}

.discount-value-input.ant-input::placeholder {
  color: #bfbfbf !important;
  font-family: Inter;
  font-size: 1em !important;
  font-weight: 400;
}

.discount-value-input {
  border: 1px solid #fff;
  height: 6vh;
  font-size: 1vw;
}

/* Parked Bills */

.parkedTable .ant-table-thead > tr > th {
  font-size: 1.37vw !important;
}

.parkedTable .ant-table-thead > tr > th {
  border-bottom: none !important;
  font-weight: 600;
  color: #0f0718;
}

.parkedTable .ant-table-tbody > tr > td {
  font-weight: 500;
  color: #0f0718;
}

.parkedTable .ant-table-tbody > tr > td:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.parkedTable .ant-table-thead > tr > th:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.parkedTable .ant-table-tbody > tr > td:nth-child(5) {
  padding-left: 0;
}

.ant-table-expanded-row > td {
  padding: 0;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding-left: 0;
  padding-right: 0;
}

.parkedTable .ant-table-thead > tr > th:nth-child(5) {
  padding-left: 0;
}

.parkedTable .ant-table-tbody > tr > td:nth-child(3) {
  text-align: center !important;
}

.parkedTable .ant-table-thead > tr > th:nth-child(3) {
  text-align: center !important;
}

.parkedTable .ant-table-tbody > tr > td:nth-child(4) {
  text-align: center !important;
}

.parkedTable .ant-table-thead > tr > th:nth-child(4) {
  text-align: center !important;
}

.td-bottons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* font-size: 0.9vw; */
  font-size: 1vw;
  font-weight: 500;
}

.parkedTable .ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding-top: 1.6vw;
  padding-bottom: 1.6vw;
  font-size: 1.2vw;
}

.td-nested-title {
  font-size: 1.23vw !important;
  font-weight: 600;
}

.td-subtotal {
  font-size: 1vw;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(52, 40, 44, 0.55);
  padding-bottom: 2px;
  font-weight: 400;
}

.td-subTotalText {
  font-size: 1em;
  color: #0f0718;
  font-weight: 400;
}

.td-total {
  font-weight: 500;
  font-size: 1em;
}

/* .td-nested-description {
  font-size: 1.2em !important;
  font-weight: 400;
} */

.parkedCustomer {
  height: 3.8vw;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
}

.parkedCustomer .ant-input-affix-wrapper > input.ant-input {
  color: #929098 !important;
  letter-spacing: 0.08vh !important;
  font-size: 12px !important;
}

.parkedCustomer .ant-input {
  font-size: 1.2vw !important;
  font-weight: 500 !important;
  color: #000 !important;
  margin-left: -0.5vw !important;
}

.parkedCustomer .ant-input::-webkit-input-placeholder {
  color: #929098 !important;
}

.parkedCustomer .ant-input:-ms-input-placeholder {
  color: #929098 !important;
}

.parkedCustomer .ant-input::placeholder {
  color: #929098 !important;
}

/* Sales History  */

.salesHistory-input {
  width: 100%;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  height: 6.6vh;
}

.salesHistory-input .ant-input {
  font-size: 1.2vw !important;
  font-weight: 500 !important;
  color: #000 !important;
  margin-left: -0.5vw !important;
}

.salesHistory-input .ant-input::-webkit-input-placeholder {
  color: #929098 !important;
  font-weight: 500 !important;
}

.salesHistory-input .ant-input:-ms-input-placeholder {
  color: #929098 !important;
  font-weight: 500 !important;
}

.salesHistory-input .ant-input::placeholder {
  color: #929098 !important;
  font-weight: 500 !important;
}

.salesHistory-doc-input.ant-input::-webkit-input-placeholder {
  color: #929098 !important;
  font-weight: 500 !important;
  font-size: 1.2vw;
}

.salesHistory-doc-input.ant-input:-ms-input-placeholder {
  color: #929098 !important;
  font-weight: 500 !important;
  font-size: 1.2vw;
}

.salesHistory-doc-input.ant-input::placeholder {
  color: #929098 !important;
  font-weight: 500 !important;
  font-size: 1.2vw;
}

.salesHistory-doc-input .ant-input {
  font-size: 1.2vw !important;
  font-weight: 500 !important;
  color: #000 !important;
  margin-left: -0.5vw !important;
}

.salesHistory-doc-input {
  width: 100%;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  height: 6.6vh;
}

.salesHistory-datePicker {
  width: 100%;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  height: 6.6vh;
}

.salesHistory-datePicker .ant-picker-input {
  flex-direction: row-reverse;
}

.salesHistory-datePicker .ant-picker-input input::-webkit-input-placeholder {
  color: #929098 !important;
  font-weight: 500 !important;
  font-size: 1.2vw;
}

.salesHistory-datePicker .ant-picker-input input:-ms-input-placeholder {
  color: #929098 !important;
  font-weight: 500 !important;
  font-size: 1.2vw;
}

.salesHistory-datePicker .ant-picker-input input::placeholder {
  color: #929098 !important;
  font-weight: 500 !important;
  font-size: 1.2vw;
}

.ant-picker-suffix {
  margin-right: 0.9vw;
}

.ant-picker-suffix {
  /* Adjust icon positioning */
  right: auto !important;
  left: 8px;
}

.salesHistory-table .ant-table-thead > tr > th {
  font-size: 1.4vw !important;
  color: #0f0718;
  border-bottom: none !important;
  font-weight: 600;
}

.salesHistory-table .ant-table-tbody > tr:first-child > td {
  border-bottom: 0px solid rgba(0, 0, 0, 0.27) !important;
}

.salesHistory-table .ant-table-tbody > tr > td {
  font-weight: 500;
  color: #0f0718;
}

.salesHistory-table .ant-table-thead > tr > th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.27) !important;
}

.salesHistory-table .ant-table-tbody > tr > td:nth-child(5) {
  text-align: center !important;
}

.salesHistory-table .ant-table-thead > tr > th:nth-child(5) {
  text-align: center !important;
}

.salesHistory-table .ant-table-tbody > tr > td:nth-child(3) {
  text-align: center !important;
}

.salesHistory-table .ant-table-thead > tr > th:nth-child(3) {
  text-align: center !important;
}

.salesHistory-table .ant-table-tbody > tr > td:nth-child(4) {
  text-align: center !important;
}

.salesHistory-table .ant-table-thead > tr > th:nth-child(4) {
  text-align: center !important;
}

.custom-border {
  position: relative;
  height: 1px;
}

.custom-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-top: 2px dashed;
  margin-top: 8px;
}

/* Payment scree */
.itemScroll {
  min-height: 15vh;
  max-height: 30vh;
  height: auto;
  padding-left: 1.55%;
  margin-top: 0.4vw;
}

.payment-details {
  color: #0f0718;
  font-weight: 600;
  font-size: 1.05vw;
  margin-bottom: 4px;
}

/* Cash Management */

.cash-management .ant-table-thead > tr > th {
  font-size: 1.37vw !important;
  font-weight: 600;
  color: #0f0718;
}

.cash-management .ant-table-tbody > tr > td {
  font-weight: 550;
  color: #0f0718;
}

.cash-management .ant-table-tbody > tr > td:nth-child(4) {
  text-align: right !important;
  font-size: 1.37vw !important;
}

.cash-management .ant-table-tbody > tr > td:nth-child(3) {
  text-align: left !important;
  padding-left: 1.2vw !important;
}

.cash-management .ant-table-thead > tr > th:nth-child(4) {
  padding-right: 0px !important;
}

.cash-management .ant-table-thead > tr > th:nth-child(1) {
  padding-left: 0px !important;
}

/* Sales Return */

.salesReturn-table {
  height: 51vh;
}

.salesReturn-table .ant-table-thead > tr > th {
  font-size: 1.4vw !important;
  color: #0f0718;
  border-bottom: none !important;
  font-weight: 600;
}

.salesReturn-table .ant-table-tbody > td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.27) !important;
}

.salesReturn-table .ant-table-tbody > tr > td {
  font-weight: 500;
  color: #0f0718;
}

.salesReturn-table .ant-table-tbody > tr {
  font-weight: 500;
  color: #0f0718;
}

.salesReturn-table .ant-table-thead > tr > th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.27) !important;
}

.salesReturn-table .ant-table-tbody > tr > td:nth-child(5) {
  text-align: center !important;
}

.salesReturn-table .ant-table-tbody > tr > td:nth-child(1) {
  padding-left: 1vw;
}

.salesReturn-table .ant-table-tbody > tr > td:nth-child(2) {
  text-align: center !important;
}

.salesReturn-table .ant-table-thead > tr > th:nth-child(2) {
  text-align: center !important;
}

.salesReturn-table .ant-table-thead > tr > th:nth-child(5) {
  text-align: center !important;
}

.salesReturn-table .ant-table-tbody > tr > td:nth-child(3) {
  text-align: center !important;
}

.salesReturn-table .ant-table-thead > tr > th:nth-child(3) {
  text-align: center !important;
}

.salesReturn-table .ant-table-tbody > tr > td:nth-child(4) {
  text-align: center !important;
  padding-right: 0px !important;
}

.salesReturn-table .ant-table-thead > tr > th:nth-child(4) {
  text-align: center !important;
}

.sm-stock-table .ant-table {
  font-weight: 500;
  font-size: 1vw;
  padding: 0px 1em;
  background: #fff !important;
}

.sm-stock-table .ant-table-tbody > tr > td {
  font-size: 0.8em !important;
  color: #0f0718 !important;
  font-weight: 500;
  background-color: #fff !important;
  /* border-bottom: 1px solid #00000045 !important; */
  padding: 0.7vw 0.4vw !important;
  text-align: left !important;
}

.sm-stock-table .ant-table-thead > tr > th {
  font-size: 1em !important;
  color: #0f0718 !important;
  font-weight: 500;
  background-color: #fff !important;
  padding: 0.2vw 0.4vw !important;
  /* border-bottom: 1px solid #00000045 !important; */
  text-align: left !important;
}

/* Super Market */

.sm-stock-table-BOM .ant-table {
  font-weight: 500;
  font-size: 1vw;
  padding: 0px 1em;
  background: #fff !important;
}

.sm-stock-table-BOM .ant-table-tbody > tr > td {
  font-size: 0.8em !important;
  color: #0f0718 !important;
  font-weight: 500;
  background-color: #fff !important;
  text-align: left !important;
}

.sm-stock-table-BOM .ant-table-thead > tr > th {
  font-size: 1em !important;
  color: #0f0718 !important;
  font-weight: 500;
  background-color: #fff !important;
  padding: 0.2vw 0.4vw !important;
  text-align: left !important;
}

.sm-cart-table .ant-table {
  font-weight: 500;
  font-size: 1vw;
}

.sm-cart-table .ant-table-thead > tr > th {
  font-size: 1.25em !important;
  color: #0f0718 !important;
  font-weight: 500;
  background-color: #fff !important;
  padding: 1vh 1.2vw !important;
  border-bottom: 1px solid #00000045 !important;
}

.sm-cart-table .ant-table-thead > tr > td {
  font-size: 1em !important;
  color: #0f0718 !important;
  font-weight: 400 !important;
}

.sm-cart-table .ant-table-tbody > tr > td {
  border-bottom: 1px solid #00000012 !important;
  font-size: 1.2em;
}

.sm-cart-table .ant-table-thead > tr > th:nth-child(6) {
  text-align: left !important;
}

.sm-cart-table .ant-table-tbody > tr > td:nth-child(6) {
  text-align: left !important;
  padding-left: 16px;
}

.sm-cart-table .ant-table-thead > tr > th:nth-child(7) {
  text-align: left !important;
}

.sm-cart-table .ant-table-tbody > tr > td:nth-child(7) {
  text-align: left !important;
  padding-left: 16px;
}

.sm-cart-table .ant-table-tbody > tr > td {
  color: #0f0718 !important;
  font-weight: 400 !important;
}

.sm-cart-table .ant-table-tbody > tr {
  border-bottom: none !important;
}

.sm-cart-table .ant-table-tbody > tr > td:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.sm-cart-table .ant-table-thead > tr > th:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.sm-cart-table .ant-table-tbody > tr > td:nth-child(5) {
  padding-left: 0;
}

.sm-cart-table .ant-table-thead > tr > th:nth-child(5) {
  padding-left: 0;
}

.sm-cart-table .ant-table-tbody > tr > td:nth-child(3) {
  text-align: center !important;
}

.sm-cart-table .ant-table-thead > tr > th:nth-child(3) {
  text-align: center !important;
}

.sm-cart-table .ant-table-tbody > tr > td:nth-child(4) {
  text-align: center !important;
}

.sm-cart-table .ant-table-thead > tr > th:nth-child(4) {
  text-align: center !important;
}

.sm-cart-table .ant-table-thead {
  padding-left: 1% !important;
}

/* dashboard table */
.sm-dashboard-table .ant-table {
  font-weight: 500;
  font-size: 1vw;
  background: #fff !important;
}

.sm-dashboard-table .ant-table-thead > tr > th {
  font-size: 1.25em !important;
  color: #0f0718 !important;
  font-weight: 500;
  background-color: #fff !important;
  padding: 1vh 1.2vw !important;
  border-bottom: 1px solid #00000045 !important;
}

.sm-dashboard-table .ant-table-thead > tr > td {
  font-size: 1em !important;
  color: #0f0718 !important;
  font-weight: 400 !important;
}

.sm-dashboard-table .ant-table-tbody > tr > td {
  border-bottom: 1px solid #00000012 !important;
  font-size: 1.2em;
}

.sm-dashboard-table .ant-table-thead > tr > th:nth-child(6) {
  text-align: left !important;
}

.sm-dashboard-table .ant-table-tbody > tr > td:nth-child(6) {
  text-align: left !important;
  padding-left: 16px;
}

.sm-dashboard-table .ant-table-tbody > tr > td {
  color: #0f0718 !important;
  font-weight: 400 !important;
}

.sm-dashboard-table .ant-table-tbody > tr {
  border-bottom: none !important;
}

.sm-dashboard-table .ant-table-tbody > tr > td:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.sm-dashboard-table .ant-table-thead > tr > th:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.sm-dashboard-table .ant-table-tbody > tr > td:nth-child(5) {
  padding-left: 0;
}

.sm-dashboard-table .ant-table-thead > tr > th:nth-child(5) {
  padding-left: 0;
}

/* .sm-dashboard-table .ant-table-tbody > tr > td:nth-child(3) {
  text-align: left !important;
} */

.sm-dashboard-table .ant-table-thead > tr > th:nth-child(3) {
  text-align: left !important;
}

/* .sm-dashboard-table .ant-table-thead > tr > th:nth-child(2) {
  text-align: left !important;
  padding-left: 8vw !important;
} */

.sm-dashboard-table .ant-table-tbody > tr > td:nth-child(4) {
  text-align: left !important;
  padding-left: 1.2vw !important;
}

.sm-dashboard-table .ant-table-thead > tr > th:nth-child(4) {
  text-align: left !important;
}

.sm-dashboard-table .ant-table-tbody > tr > td:nth-child(1) {
  text-align: left !important;
  padding-left: 16px;
}

/* .sm-dashboard-table .ant-table-thead > tr > th:nth-child(2) {
  text-align: left !important;
} */

.sm-dashboard-table .ant-table-tbody > tr > td:nth-child(2) {
  text-align: left !important;
  padding-left: 16px;
}

.sm-dashboard-table .ant-table-tbody > tr > td:nth-child(3) {
  text-align: left !important;
  padding-left: 16px;
}

.sm-dashboard-table .ant-table-thead > tr > th:nth-child(2) {
  text-align: left !important;
}

.sm-dashboard-table .ant-table-thead {
  padding-left: 1% !important;
}

/* dashboard table */

/* view order table */
.sm-view-order-table .ant-table {
  font-weight: 500;
  font-size: 1vw;
}

.sm-view-order-table .ant-table-thead > tr > th {
  font-size: 1.25em !important;
  color: #0f0718 !important;
  font-weight: 500;
  background-color: #fff !important;
  padding: 1vh 1.2vw !important;
  border-bottom: 1px solid #00000045 !important;
}

.sm-view-order-table .ant-table-thead > tr > td {
  font-size: 1em !important;
  color: #0f0718 !important;
  font-weight: 400 !important;
}

.sm-view-order-table .ant-table-tbody > tr > td {
  border-bottom: 1px solid #00000012 !important;
  font-size: 1.2em;
}

.sm-view-order-table .ant-table-thead > tr > th:nth-child(6) {
  text-align: left !important;
}

.sm-view-order-table .ant-table-tbody > tr > td:nth-child(6) {
  text-align: left !important;
  padding-left: 16px;
}

.sm-view-order-table .ant-table-tbody > tr > td {
  color: #0f0718 !important;
  font-weight: 400 !important;
}

.sm-view-order-table .ant-table-tbody > tr {
  border-bottom: none !important;
}

.sm-view-order-table .ant-table-tbody > tr > td:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.sm-view-order-table .ant-table-thead > tr > th:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.sm-view-order-table .ant-table-tbody > tr > td:nth-child(5) {
  /* padding-left: 0; */
  text-align: center !important;
  padding-right: 35px !important;
}

.sm-view-order-table .ant-table-thead > tr > th:nth-child(5) {
  padding-left: 0;
}

.sm-view-order-table .ant-table-tbody > tr > td:nth-child(3) {
  text-align: left !important;
  padding-left: 16px !important;
}

.sm-view-order-table .ant-table-thead > tr > th:nth-child(3) {
  text-align: left !important;
}

.sm-view-order-table .ant-table-tbody > tr > td:nth-child(4) {
  text-align: center !important;
  /* padding-left: 1.5vw !important; */
}

.sm-view-order-table .ant-table-thead > tr > th:nth-child(4) {
  text-align: center !important;
  /* padding-left: 1.5vw !important; */
}

.sm-view-order-table .ant-table-tbody > tr > td:nth-child(2) {
  text-align: left !important;
  padding-left: 16px;
}

.sm-view-order-table .ant-table-thead > tr > th:nth-child(2) {
  text-align: left !important;
}

.sm-view-order-table .ant-table-thead {
  padding-left: 1% !important;
}

/* cash management table */

.sm-cashmanagement-table .ant-table {
  font-weight: 500;
  /* font-size: 1.1vh; */
  font-size: 1vw;
}
.sm-cashmanagement-table .ant-table-thead > tr > th {
  /* font-size: 1.33vw !important; */
  font-size: 1.25em !important;
  color: #0f0718 !important;
  font-weight: 500;
  background-color: #fff !important;
  padding: 1vh 1.2vw !important;
  border-bottom: 1px solid #00000045 !important;
}
.sm-cashmanagement-table .ant-table-thead > tr > td {
  font-size: 1.03vw !important;
  color: #0f0718 !important;
  font-weight: 500 !important;
}
.sm-cashmanagement-table .ant-table-tbody > tr > td {
  border-bottom: 1px solid #00000012 !important;
  background-color: #fff !important;
  padding-left: 1vw;
  font-size: 1.2em !important;
  font-weight: 400 !important;
  color: #0f0718 !important;
}

.sm-cashmanagement-table .ant-table-tbody > tr > td:nth-child(4) {
  text-align: center !important;
  padding-right: 2vw;
}

.addtInfo {
  font-size: 1.2em;
  font-weight: 400;
  color: #0f0718;
  font-family: Inter;
}

.ant-progress-text {
  color: #0f0718 !important;
  font-size: 1em !important;
  font-weight: 400 !important;
}

/* Super Market Return */
.sm-cart-table-return .ant-table {
  font-weight: 500;
  font-size: 1vw;
}

.sm-cart-table-return .ant-table-thead > tr > th {
  font-size: 1.25em !important;
  color: #0f0718 !important;
  font-weight: 500;
  background-color: #fff !important;
  padding: 1vh 1.2vw !important;
  border-bottom: 1px solid #fff !important;
}

.sm-cart-table-return .ant-table-thead > tr > td {
  /* font-size: 1.03vw !important; */
  color: #0f0718 !important;
  font-weight: 500 !important;
}

.sm-cart-table-return .ant-table-tbody > tr > td {
  border-bottom: 1px solid #fff !important;
  background: #fff;
  font-size: 1.2em !important;
}

.sm-cart-table-return .ant-table-thead > tr > th:nth-child(6) {
  text-align: center !important;
}

/* .sm-cart-table-return .ant-table-thead>tr>td:nth-child(6) {
  text-align: center !important;
} */

.sm-cart-table-return .ant-table-tbody > tr > td:nth-child(6) {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  padding-left: 5vw;
}

.sm-cart-table-return .ant-table-tbody > tr > td {
  color: #0f0718 !important;
  font-weight: 400 !important;
}

.sm-cart-table-return .ant-table-tbody > tr > td:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.sm-cart-table-return .ant-table-thead > tr > th:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.sm-cart-table-return .ant-table-tbody > tr > td:nth-child(5) {
  padding-left: 0;
}

.sm-cart-table-return .ant-table-thead > tr > th:nth-child(5) {
  padding-left: 0;
}

.sm-cart-table-return .ant-table-tbody > tr > td:nth-child(3) {
  text-align: center !important;
}

.sm-cart-table-return .ant-table-thead > tr > th:nth-child(3) {
  text-align: center !important;
}

.sm-cart-table-return .ant-table-tbody > tr > td:nth-child(4) {
  text-align: center !important;
}

.sm-cart-table-return .ant-table-thead > tr > th:nth-child(4) {
  text-align: center !important;
}

.sm-cart-table-return .ant-table-tbody > tr > td:nth-child(2) {
  text-align: left !important;
  padding-left: 1vw;
}

.sm-cart-table-return .ant-table-thead > tr > th:nth-child(2) {
  text-align: left !important;
}

.sm-cart-table-return .ant-table-tbody > tr > td:nth-child(1) {
  padding: 1.05vw !important;
}

/* //////////////////// */

.sm-payment-search .ant-input {
  text-align: right !important;
  font-weight: 600 !important;
}

.ant-table-expanded-row > td {
  padding: 0;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding-left: 0;
  padding-right: 0;
}

.td-nested-description-sm {
  /* font-size: 1.1vw !important; */
  font-weight: 400;
  /* border-bottom: 1px solid rgba(52, 40, 44, 0.55); */
}

/* .td-nested-title-sm {
  font-size: 1.1vw !important;
  font-weight: 600;
} */

/* For pos table */

.sm-cart-table-pos .ant-table {
  font-weight: 500;
  font-size: 1vw;
}

.sm-cart-table-pos .ant-table-thead > tr > th {
  font-size: 1em !important;
  color: #0f0718 !important;
  font-weight: 500;
  background-color: var(--table-bg-color) !important;
  padding: 1.2vh 0.9vw !important;
  border-bottom: none !important;
  font-family: Inter !important;
}

.sm-cart-table-pos .ant-table-thead > tr > td {
  /* font-size: 1.03vw !important; */
  color: #0f0718 !important;
  font-weight: 500 !important;
}

.sm-cart-table-pos .ant-table-thead > tr > th:nth-child(6) {
  text-align: center !important;
}

.sm-cart-table-pos .ant-table-tbody > tr > td {
  padding: 1vh;
  font-size: 1vw;
  color: #0f0718 !important;
  font-weight: 500 !important;
}

.sm-cart-table-pos .ant-table-tbody > tr {
  border-bottom: 1px solid #908f96 !important;
}

.sm-cart-table-pos .ant-table-tbody > tr > td:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.sm-cart-table-pos .ant-table-thead > tr > th:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.sm-cart-table-pos .ant-table-tbody > tr > td:nth-child(5) {
  padding-left: 0;
}

.sm-cart-table-pos .ant-table-thead > tr > th:nth-child(5) {
  padding-left: 0;
}

.sm-cart-table-pos .ant-table-tbody > tr > td:nth-child(3) {
  text-align: center !important;
}

.sm-cart-table-pos .ant-table-thead > tr > th:nth-child(3) {
  text-align: center !important;
}

.sm-cart-table-pos .ant-table-thead > tr > th:nth-child(2) {
  text-align: center !important;
}

.sm-cart-table-pos .ant-table-tbody > tr > td:nth-child(2) {
  text-align: center !important;
}

.sm-cart-table-pos .ant-table-tbody > tr > td:nth-child(4) {
  text-align: center !important;
}

.sm-cart-table-pos .ant-table-thead > tr > th:nth-child(4) {
  text-align: center !important;
}

/* .parkedCustomer-sm {
  height: 2.8vw;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.parkedCustomer-sm .ant-input-affix-wrapper > input.ant-input {
  color: #929098 !important;
  letter-spacing: 0.08vh !important;
  font-size: 12px !important;
}

.parkedCustomer-sm .ant-input {
  font-size: 1.2vw !important;
  font-weight: 500 !important;
  color: #000 !important;
  margin-left: -0.5vw !important;
}

.parkedCustomer-sm .ant-input::placeholder {
  color: #929098 !important;
  opacity: 0.8;
} */

.sm-cart-table-pos-stock .ant-table {
  font-weight: 500;
  font-size: 1vw;
}

.sm-cart-table-pos-stock .ant-table-thead > tr > th {
  font-size: 1em !important;
  color: #0f0718 !important;
  font-weight: 500;
  background-color: var(--table-bg-color) !important;
  padding: 1.2vh 0.9vw !important;
  border-bottom: none !important;
  font-family: Inter !important;
}

.sm-cart-table-pos-stock .ant-table-thead > tr > td {
  /* font-size: 1.03vw !important; */
  color: #0f0718 !important;
  font-weight: 500 !important;
}

.sm-cart-table-pos-stock .ant-table-thead > tr > th:nth-child(6) {
  text-align: center !important;
}

.sm-cart-table-pos-stock .ant-table-tbody > tr > td {
  padding: 1vh;
  font-size: 1vw;
  color: #0f0718 !important;
  font-weight: 500 !important;
}

.sm-cart-table-pos-stock .ant-table-tbody > tr {
  border-bottom: 1px solid #908f96 !important;
}

.sm-cart-table-pos-stock .ant-table-tbody > tr > td:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.sm-cart-table-pos-stock .ant-table-thead > tr > th:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.sm-cart-table-pos-stock .ant-table-tbody > tr > td:nth-child(3) {
  text-align: right !important;
  padding-right: 1vw;
}

.sm-cart-table-pos-stock .ant-table-thead > tr > th:nth-child(3) {
  text-align: right !important;
}

.sm-cart-table-pos-stock .ant-table-tbody > tr > td:nth-child(2) {
  text-align: left !important;
  padding-left: 1vw;
}

.sm-cart-table-pos-stock .ant-table-thead > tr > th:nth-child(2) {
  text-align: left !important;
}

.sm-cart-table-pos-stock .ant-table-tbody > tr > td:nth-child(4) {
  text-align: right !important;
  padding-right: 1vw;
}

.sm-cart-table-pos-stock .ant-table-thead > tr > th:nth-child(4) {
  text-align: right !important;
}

.parkedCustomer-sm {
  height: 2.8vw;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  font-size: 1vw;
}

.parkedCustomer-sm .ant-input-affix-wrapper > input.ant-input {
  color: #929098 !important;
  letter-spacing: 0.08vh !important;
  font-size: 12px !important;
}

.parkedCustomer-sm .ant-input {
  /* font-size: 1.2vw !important; */
  font-size: 1em;
  font-weight: 400 !important;
  color: #929098 !important;
  /* color: #000 !important; */
  margin-left: -0.8vw !important;
}

.parkedCustomer-sm .ant-input::-webkit-input-placeholder {
  color: #929098 !important;
  opacity: 0.8;
}

.parkedCustomer-sm .ant-input:-ms-input-placeholder {
  color: #929098 !important;
  opacity: 0.8;
}

.parkedCustomer-sm .ant-input::placeholder {
  color: #929098 !important;
  opacity: 0.8;
}

/* .salesHistory-input-sm {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #fff;
  height: 5vh;
}

.salesHistory-input-sm .ant-input {
  font-size: 1vw !important;
  font-weight: 500 !important;
  color: #000 !important;
  margin-left: -0.5vw !important;
}

.salesHistory-input-sm .ant-input::placeholder {
  color: #929098 !important;
  font-weight: 500 !important;
  opacity: 0.8;
} */

.salesHistory-input-sm {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #fff;
  font-size: 1vw;
  /* height: 5vh; */
}
/* .sm-product-search{
  font-size: 0.95rem;
} */

.salesHistory-input-sm .ant-input {
  font-size: 1em !important;
  font-weight: 400 !important;
  /* color: #000 !important; */
  margin-left: -0.5vw !important;
}

.salesHistory-input-sm .ant-input::-webkit-input-placeholder {
  color: #929098 !important;
  font-weight: 400 !important;
  opacity: 0.8;
}

.salesHistory-input-sm .ant-input:-ms-input-placeholder {
  color: #929098 !important;
  font-weight: 400 !important;
  opacity: 0.8;
}

.salesHistory-input-sm .ant-input::placeholder {
  color: #929098 !important;
  font-weight: 400 !important;
  opacity: 0.8;
}

.salesHistory-doc-input-sm.ant-input::-webkit-input-placeholder {
  color: #929098 !important;
  font-weight: 400 !important;
  font-size: 1vw !important;
  opacity: 0.8;
}

.salesHistory-doc-input-sm.ant-input:-ms-input-placeholder {
  color: #929098 !important;
  font-weight: 400 !important;
  font-size: 1vw !important;
  opacity: 0.8;
}

.salesHistory-doc-input-sm.ant-input::placeholder {
  color: #929098 !important;
  font-weight: 400 !important;
  font-size: 1vw !important;
  opacity: 0.8;
}

.salesHistory-doc-input-sm .ant-input {
  font-size: 1.2vw !important;
  font-weight: 500 !important;
  color: #000 !important;
  margin-left: -0.5vw !important;
}

.salesHistory-doc-input-sm {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #fff;
  height: 5vh;
}

.salesHistory-datePicker-sm {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #fff;
  height: 5vh;
}

.salesHistory-datePicker-sm .ant-picker-input {
  flex-direction: row-reverse;
}

.salesHistory-datePicker-sm .ant-picker-input input::-webkit-input-placeholder {
  color: #929098 !important;
  font-weight: 500 !important;
  font-size: 1vw;
  opacity: 0.8;
}

.salesHistory-datePicker-sm .ant-picker-input input:-ms-input-placeholder {
  color: #929098 !important;
  font-weight: 500 !important;
  font-size: 1vw;
  opacity: 0.8;
}

.salesHistory-datePicker-sm .ant-picker-input input::placeholder {
  color: #929098 !important;
  font-weight: 500 !important;
  font-size: 1vw;
  opacity: 0.8;
}

/* Kiosk screen Table */

.kiosk-cart-table-pos .ant-table {
  font-weight: 500;
  font-size: 1.1vh;
}

.kiosk-cart-table-pos .ant-table-thead > tr > th {
  font-size: 1.03vw !important;
  color: #0f0718 !important;
  font-weight: 600;
  background-color: #fff !important;
  padding: 1.2vh 0.9vw !important;
  border-bottom: 1px solid rgba(146, 144, 152, 0.5) !important;
}

.kiosk-cart-table-pos .ant-table-thead > tr > td {
  font-size: 1.03vw !important;
  color: #0f0718 !important;
  font-weight: 500 !important;
}

.kiosk-cart-table-pos .ant-table-tbody > tr > td {
  border-bottom: 1px solid rgba(146, 144, 152, 0.5) !important;
}

.kiosk-cart-table-pos .ant-table-thead > tr > th:nth-child(6) {
  text-align: center !important;
}

.kiosk-cart-table-pos .ant-table-thead > tr > td:nth-child(6) {
  text-align: left !important;
}

.kiosk-cart-table-pos .ant-table-tbody > tr > td {
  padding: 1.5vh;
  color: #0f0718 !important;
  font-weight: 500 !important;
  height: 8.6vh;
}

.kiosk-cart-table-pos .ant-table-tbody > tr {
  border-bottom: none !important;
}

.kiosk-cart-table-pos .ant-table-tbody > tr > td:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.kiosk-cart-table-pos .ant-table-thead > tr > th:nth-child(5) {
  text-align: center !important;
  padding-right: 0;
}

.kiosk-cart-table-pos .ant-table-tbody > tr > td:nth-child(5) {
  padding-left: 0;
}

.kiosk-cart-table-pos .ant-table-thead > tr > th:nth-child(5) {
  padding-left: 0;
}

.kiosk-cart-table-pos .ant-table-tbody > tr > td:nth-child(3) {
  text-align: center !important;
}

.kiosk-cart-table-pos .ant-table-thead > tr > th:nth-child(3) {
  text-align: center !important;
}

.kiosk-cart-table-pos .ant-table-tbody > tr > td:nth-child(4) {
  text-align: center !important;
}

.kiosk-cart-table-pos .ant-table-thead > tr > th:nth-child(4) {
  text-align: center !important;
}

.KioskDescription {
  list-style: none;
  font-size: 1vw;
  padding-top: 2vh;
  font-weight: 500;
  color: #0f0718;
  line-height: 1.2;
  /* listStyle: 'none', fontSize:"1.2vh",paddingTop:"2vh" */
}

.offers-text {
  padding: 1.16vh;
  margin-bottom: 0;
  font-size: 0.75vw;
  font-family: Inter, sans-serif;
  color: #fff;
  font-weight: normal;
  width: 80%;
  overflow: hidden;
  background-color: #626367;
  text-align: center;
  border-radius: 3px;
  font-weight: 400;
}

.custom-menu-item .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  padding: 0 10px !important;
}

.ant-drawer-mask {
  background: none;
}

.ant-drawer-content {
  background: #f3f4f9;
}

.filter > .ant-drawer-mask {
  background: #000000a6;
}

.ant-drawer-content {
  border-radius: 10px !important;
}

.ant-drawer-header {
  background: #f3f4f9;
  border-bottom: 1px solid #f3f4f9;
}

.addCustomer > .ant-drawer-mask {
  background: #000000a6;
}

.ant-drawer-content {
  border-radius: 10px !important;
}

/* Keyboard styles */

.qrlogin-btn {
  width: 100%;
  background-color: #0083c6 !important;
  color: #ffffff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border: 1px solid #0083c6;
}

.hg-theme-default {
  background-color: #fff !important;
}

.email.hg-theme-default {
  background-color: transparent;
}

.email.simple-keyboard.hg-layout-default .hg-button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  align-items: center;
  background: #fff;
  /* box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1); */
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 1.7rem;
  border-radius: 0.9rem;
  height: 9vh;
  justify-content: center;
  padding: 0px;
  margin: 0.2rem;
}

.email.simple-keyboard.hg-theme-default .hg-row:nth-child(2) {
  margin-left: 2vw;
  margin-right: 2vw;
}

.simple-keyboard.hg-layout-default .hg-button.hg-yellow {
  /* background: #2f3856 !important; */
  color: #000 !important;
  width: 13vw;
}

.simple-keyboard.hg-layout-default .hg-button.hg-mail {
  width: 13vw;
}

.email.simple-keyboard.hg-theme-default .hg-row:nth-child(4) {
  margin-left: 3vw;
  margin-right: 3vw;
}

.hg-theme-default .hg-button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  align-items: center;
  background: #fff;
  /* box-shadow: 0px 3px 6px #00000026 !important;
  border-bottom: 1px solid #fff !important; */
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 1.7rem;
  border-radius: 0.9rem;
  height: 7.6vh;
  justify-content: center;
  padding: 0px;
  margin: 0.2rem;
}

.hg-theme-default .hg-row:nth-child(2) {
  padding: 0;
}

.qtyReturn.ant-input:focus {
  box-shadow: 0 0 0 2px #fff !important;
  border-color: #92909880 !important;
}

.ant-switch-checked {
  background-color: #2f3856 !important;
}

.custom-dropdown {
  /* Add your styles for the dropdown options here */
  /* For example: */
  background-color: #f0f0f0;
  color: #333;
  font-size: 16px;
}
/* Notes Popup Styles */

.aprvl.hg-theme-default {
  background-color: transparent !important;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: Inter;
  overflow: hidden;
  touch-action: manipulation;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  width: 100%;
  font-size: 1vw;
}
.aprvl.hg-theme-default .hg-row .hg-button {
  align-items: center;
  background: rgba(146, 144, 152, 0.5);
  color: #0f0718;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 1.5em;
  font-family: Inter;
  font-weight: 500;
  justify-content: center;
  padding: 0px;
  margin: 0.2rem;
}

.tableSelected {
  background-color: #f5f5f5;
}

.tableNonSelected {
  background-color: #fff;
}

.custom-tabs .ant-tabs-nav .ant-tabs-nav-wrap {
  display: flex;
  justify-content: flex-end;
}

.custom-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 20px;
}

.floor-buttons {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  padding-top: 2.5vh;
  padding-right: 22px;
  padding-left: 22px;
  font-size: 1vw;
}

.floor-button {
  border-radius: 35px;
  padding: 5px 15px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  color: #929098;
  font-size: 1em;
  font-weight: 500;
  font-family: "Inter";
  height: 5.5vh;
}

/* .ant-drawer-header {
  background: #fff;
  border-bottom: none;
} */

.custom-select .ant-select-selector {
  height: 8vh; /* Adjust the height as needed */
  line-height: 8vh; /* Ensure text is centered vertically */
}

.custom-select .ant-select-selection-item {
  line-height: 8vh; /* Ensure selected item text is centered */
  border-radius: 3px;
}

.custom-select .ant-select-arrow {
  height: 8vh; /* Ensure arrow is vertically centered */
}

.floor-button.selected {
  background-color: #2f3856;
  color: white;
  border: 1px solid #2f3856;
  font-size: 1em;
  font-weight: 500;
  font-family: "Inter";
}

.tables-container {
  padding: 20px;
  position: relative;
}

.tables-container-category {
  padding: 2vh 1.5vw;
}

/* .table-card {
  border-radius: 15px;
  text-align: center;
  padding: 20px;
  font-size: 16px;
  color: #fff;
  font-size: 1vw;
}

.table-card.OPN {
  background-color: rgba(146, 144, 152, 0.5);
  color: #0f0718;
  height: 17vh;
}

.table-card.RES {
  background-color: #ffbf00;
  color: #0f0718;
  height: 17vh;
}

.table-card.OCU {
  background-color: #008000;
  color: #0f0718;
  height: 17vh;
} */

.table-card {
  border-radius: 15px;
  text-align: center;
  padding: 20px;
  color: #fff;
  width: 20.5vw;
  font-size: 1vw;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.table-card.OPN {
  background-color: rgba(146, 144, 152, 0.5);
  color: #0f0718;
  height: 17vh;
  border-radius: 10px;
}

.table-card.RES {
  background-color: #ffbf00;
  color: #0f0718;
  height: 17vh;
  border-radius: 10px;
}

.table-card.OCU {
  background-color: #008000;
  color: #0f0718;
  height: 17vh;
  border-radius: 10px;
}

.table-title {
  font-size: 1.5em;
  font-weight: 500;
  color: #0f0718;
  margin-top: 1vh;
}

.table-status {
  font-size: 1em;
  font-weight: 500;
  color: #0f0718;
}

.search-input {
  border-radius: 20px;
  padding: 5px 10px;
  margin-top: 2.5vh;
  /* margin-left: 1vw; */
  font-size: 1em !important;
}

.search-input .ant-input-prefix {
  margin-right: 0.75vw;
}

.productSearch-sm .ant-input-prefix {
  margin-right: 0.8vw;
}

.productSearch-sm {
  font-size: 1vw;
}

.productSearch-sm .ant-input::-webkit-input-placeholder {
  font-size: 1em !important;
  font-weight: 500 !important;
  font-family: Inter;
  color: #929098;
}

.productSearch-sm .ant-input:-ms-input-placeholder {
  font-size: 1em !important;
  font-weight: 500 !important;
  font-family: Inter;
  color: #929098;
}

.productSearch-sm .ant-input,
.productSearch-sm .ant-input::placeholder {
  font-size: 1em !important;
  font-weight: 500 !important;
  font-family: Inter;
  color: #929098;
}

.status-indicator span {
  position: relative;
  padding-left: 25px;
  margin-right: 20px;
  line-height: 20px;
  font-size: 1em;
  font-weight: 500;
  color: #0f0718;
  font-family: "Inter";
}

.status-indicator span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.available::before {
  background-color: rgba(146, 144, 152, 0.5); /* Light grey for Available */
}

.reserved::before {
  background-color: #ffbf00; /* Yellow for Reserved */
}

.checked-in::before {
  background-color: #008000; /* Green for Checked-in */
}

.image-container {
  position: relative;
  width: 100%;
  height: auto;
}

.image-container img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to   bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0,   0.7)); /* Adjust opacity as needed */
  z-index: -1; /* Place the gradient behind the content */
}

.amt-dial-btn-restaurant {
  width: 100%;
  height: 11vh;
  border-radius: 7px;
  background: #92909880;
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: none;
  font-size: 1.6em;
  font-weight: 500;
  color: #0f0718;
  cursor: pointer;
}

.amt-dial-btn-restaurant-ent {
  width: 100%;
  height: 22.8vh;
  border-radius: 7px;
  background: #92909880;
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: none;
  font-size: 1.6em;
  font-weight: 500;
  color: #0f0718;
  cursor: pointer;
}

.amt-dial-btn-restaurant-cash-management {
  width: 8vw;
  height: 11vh;
  border-radius: 5px;
  background: rgba(146, 144, 152, 0.5);
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: 1px solid #fff;
  /* font-size: 1.2vw; */
  font-size: 1.5em;
  font-weight: 500;
  color: #0f0718 !important;
}

.amt-dial-btn-restaurant-cash-management-cancel-discount {
  width: 8vw;
  height: 11vh;
  border-radius: 5px;
  background: rgba(146, 144, 152, 0.5);
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: 1px solid #fff;
  /* font-size: 1.2vw; */
  font-size: 1.5em;
  font-weight: 500;
  color: #0f0718 !important;
}

.amt-dial-btn-restaurant-cash-management-discount {
  width: 97%;
  height: 23.2vh;
  border-radius: 5px;
  background: rgba(146, 144, 152, 0.5);
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: 1px solid #fff;
  /* font-size: 1.2vw; */
  font-size: 1.5em;
  font-weight: 500;
  color: #0f0718;
  cursor: pointer;
}

.signUp.simple-keyboard.hg-layout-default .hg-button .hg-row {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  align-items: center;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 3px -1px rgb(0 0 0 / 30%);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 1.1rem;
  border-radius: 0.8rem;
  height: 3rem;
  justify-content: center;
  padding: 0px;
  margin: 0.2rem;
  width: 1.2vw !important;
}

/* reserve table styles start */
.no-persons-btns {
  width: 100%;
  height: 45px;
  border-radius: 50%;
  border: 0.5px solid #929098;
  font-size: 1.2em;
  font-weight: 500;
  color: #0f0718;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.custom-select .ant-select-selector {
  height: 6vh !important;
  border: 1px solid #fff !important;
  color: #1e1e1e;
  font-weight: 400;
  font-size: 1em !important;
}

.custom-select.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-top: 2% !important;
}

.signUp.hg-theme-default {
  /* background-color: #fff !important; */
  border-radius: 5px;
  box-sizing: border-box;
  font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  overflow: hidden;
  touch-action: manipulation;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  width: 100%;
}
.signUp.hg-theme-default .hg-row .hg-button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  box-shadow: none;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 1.5em;
  border-radius: 0.8rem;
  font-weight: 500;
  height: 3rem;
  justify-content: center;
  padding: 0px;
  margin: 0.2rem;
  color: #0f0718;
  width: 1.2vw !important;
}
.signUp.simple-keyboard.hg-layout-default .hg-button.hg-bksp {
  width: 1.2vw;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHMAAABYCAYAAADGKMgTAAAABHNCSVQICAgIfAhkiAAABK1JREFUeJztnUFy2zYUhj+5Gy/pG6gniG5QdNUu1Rsgu+zinsC6QXKDsCeIe4KqJ6i76y7KCazssnMXEDWWE0oEiYf3IOGf+ceTjG38eB9BUsKTOaMsNcBPwAJwu/+b71yqNjsDrIE/gQelLOJqgLfAX8DThfgT8I6yD9IDNcAd8Ih+cTV9t6tFsaoQD/0ILCdVVEEL4B/0i2fVb8eXNq/u0C9WCf4wtsA51HBZNzcpvBpTaGnNqafVsfbR1RbUgnqTM8WPvHjp8sOwuifXgnBqLfqWW1nXwA1wrxmirsi0XsSVP538gHDVcd6vzNkwBklUT61yugG2V5kGqyBl5QBywKwg5bUEeZgVZB69AtlrZgWZVzOplVlBKkgCZgWpo0VqmBWknpqUMCtIZaWCWUEaUAqYFkB+Bn4j3J3PgB8JXW4a+gL8vsswA35WzBIlC2+ab+g/kG4zZ3mgv6NOOovrGXeQGmxsLJ/aNfCZcjxw+uy0FhzfnRi7V1ZAbgbm9cI5hoCE0O4hBnPMNbPr2VHbR3umzcDva4HXQhn+JayK7YDv3QhlGCUrK7LzY2R+n3j8oSuy0yrx+AcrMyKHOZCdb2MmQTqgsSAbZG8WXUwQiyCfdgWKPeX7iWPGggT4mHDOk2BaBakBdAzIDwJzHgUzR5BSgFoFOQhmKSBzALUM8iTM0kBKArUO8ijMUkFKAC0BZC/M0kGmBFoKyO/CfDmZ0j0FaEkgv4HpE/9yKx4DdElZIA9gOuUgFoHGSBvkAcxPBsKUCtQCyD1MbyBIqUCtgNzDvDcQpESglkDuYWq3fZQI1BrIPUztEKUBtQjyiZGdBueiz4zb+f87cY5kusJwOEHFtHq8VItcC8okXRE6xi5JU0B2ajEKdI7++T6Xx7xFd0zewJz218wu1MpAmNJAdvIG5nYAk91ktQOVBrKTNzBH9zxQw3kCHQPyHfmbxJLChPMDOmUbS6PrLylMCDdEW8VQFkB2Lgmo6wu0oGygKTeWSwHqjgUqFahEh0AJQN2pQKUBlWz1sA7UDQm0zBjIKsgSgDprgSyDtA7UWQs0xlvyN1+NAbpKOOfJMMEm0GXkHKaCnAJ0k2jsJDDBFtDYnY9UIMcCXSUefzJMgPeCoWK8jsicGuQYoF6wFi6iFt+oFQwWU8ghkgIZC3QlmMENrEWvWsFwQ33qmikNcijQBtn+ZHeiDoPUCgYcWsR5T7ZcIIcAlc7iUjzX5J7wp8W0/pTMNeFadL379w3wC+Eg+1UhyxvCKvxKOMhcpix/pPxlLbor9NKdtNXSk/joqIpT6r5ZTwWqJokmaE8FqqEpraMn1aJ/Hbkki6tVmtileQvyD6nxXObHH3LrAfI8PmpJ+EhAlZzWOQc7txZOa87+hk0FKuNNV+Ccn8/cEt7aqqfctHqvOXhdoek8pmUmuSrQNPaRdRdTg+yjIc7d69iC51CLfmFK85bv7OGm2M+cqnvCY5acco5S9IVQq/+UcxyVp6yPQmityNjWUjXNqdfRYyC1ujkm6Za6Sp97jYGXIFPUEFoTLxnqBkMvP1KoIUxojX5xc65EH1uoWewPGJDbuaHQa0iP1oSV2H2N1v8H9wOK7oPqIwAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40% 40%;
}

.signUp.simple-keyboard.hg-layout-default .hg-button.hg-bksp > span {
  visibility: hidden;
  width: 1.2vw;
}

.signUp.hg-theme-default .hg-row .hg-button.hg-space {
  width: 15vw !important;
}

.signUp.hg-theme-default .hg-row .hg-button.hg-bksp {
  width: 1.2vw;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHMAAABYCAYAAADGKMgTAAAABHNCSVQICAgIfAhkiAAABK1JREFUeJztnUFy2zYUhj+5Gy/pG6gniG5QdNUu1Rsgu+zinsC6QXKDsCeIe4KqJ6i76y7KCazssnMXEDWWE0oEiYf3IOGf+ceTjG38eB9BUsKTOaMsNcBPwAJwu/+b71yqNjsDrIE/gQelLOJqgLfAX8DThfgT8I6yD9IDNcAd8Ih+cTV9t6tFsaoQD/0ILCdVVEEL4B/0i2fVb8eXNq/u0C9WCf4wtsA51HBZNzcpvBpTaGnNqafVsfbR1RbUgnqTM8WPvHjp8sOwuifXgnBqLfqWW1nXwA1wrxmirsi0XsSVP538gHDVcd6vzNkwBklUT61yugG2V5kGqyBl5QBywKwg5bUEeZgVZB69AtlrZgWZVzOplVlBKkgCZgWpo0VqmBWknpqUMCtIZaWCWUEaUAqYFkB+Bn4j3J3PgB8JXW4a+gL8vsswA35WzBIlC2+ab+g/kG4zZ3mgv6NOOovrGXeQGmxsLJ/aNfCZcjxw+uy0FhzfnRi7V1ZAbgbm9cI5hoCE0O4hBnPMNbPr2VHbR3umzcDva4HXQhn+JayK7YDv3QhlGCUrK7LzY2R+n3j8oSuy0yrx+AcrMyKHOZCdb2MmQTqgsSAbZG8WXUwQiyCfdgWKPeX7iWPGggT4mHDOk2BaBakBdAzIDwJzHgUzR5BSgFoFOQhmKSBzALUM8iTM0kBKArUO8ijMUkFKAC0BZC/M0kGmBFoKyO/CfDmZ0j0FaEkgv4HpE/9yKx4DdElZIA9gOuUgFoHGSBvkAcxPBsKUCtQCyD1MbyBIqUCtgNzDvDcQpESglkDuYWq3fZQI1BrIPUztEKUBtQjyiZGdBueiz4zb+f87cY5kusJwOEHFtHq8VItcC8okXRE6xi5JU0B2ajEKdI7++T6Xx7xFd0zewJz218wu1MpAmNJAdvIG5nYAk91ktQOVBrKTNzBH9zxQw3kCHQPyHfmbxJLChPMDOmUbS6PrLylMCDdEW8VQFkB2Lgmo6wu0oGygKTeWSwHqjgUqFahEh0AJQN2pQKUBlWz1sA7UDQm0zBjIKsgSgDprgSyDtA7UWQs0xlvyN1+NAbpKOOfJMMEm0GXkHKaCnAJ0k2jsJDDBFtDYnY9UIMcCXSUefzJMgPeCoWK8jsicGuQYoF6wFi6iFt+oFQwWU8ghkgIZC3QlmMENrEWvWsFwQ33qmikNcijQBtn+ZHeiDoPUCgYcWsR5T7ZcIIcAlc7iUjzX5J7wp8W0/pTMNeFadL379w3wC+Eg+1UhyxvCKvxKOMhcpix/pPxlLbor9NKdtNXSk/joqIpT6r5ZTwWqJokmaE8FqqEpraMn1aJ/Hbkki6tVmtileQvyD6nxXObHH3LrAfI8PmpJ+EhAlZzWOQc7txZOa87+hk0FKuNNV+Ccn8/cEt7aqqfctHqvOXhdoek8pmUmuSrQNPaRdRdTg+yjIc7d69iC51CLfmFK85bv7OGm2M+cqnvCY5acco5S9IVQq/+UcxyVp6yPQmityNjWUjXNqdfRYyC1ujkm6Za6Sp97jYGXIFPUEFoTLxnqBkMvP1KoIUxojX5xc65EH1uoWewPGJDbuaHQa0iP1oSV2H2N1v8H9wOK7oPqIwAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30% 30%;
}

.signUp.hg-theme-default .hg-row .hg-button.hg-bksp > span {
  visibility: hidden;
  width: 1.2vw;
}

.signUP.hg-theme-default .hg-button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  align-items: center;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 3px -1px rgb(0 0 0 / 30%);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 1.1rem;
  border-radius: 0.8rem;
  height: 3rem;
  justify-content: center;
  padding: 0px;
  margin: 0.2rem;
  width: 1.2vw !important;
}

.mainOverFlow {
  overflow: hidden;
  height: 100vh;
}

.mainRows {
  height: 96vh;
}

.productSpecImgCenter {
  align-self: center;
}

.timePicker {
  width: 100%;
}
.datePicker {
  display: flex;
}

.section-btn {
  width: 100%;
  background-color: rgb(81, 173, 154);
  border: none;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
  text-align: center;
  height: 4vw;
}

.modal-btn {
  width: 48% !important;
  height: 4vw;
}

.number-buttons-container {
  display: flex;
  padding-bottom: 3vh;
  justify-content: center;
  align-items: center;
  grid-gap: 1vw;
  gap: 1vw; /* Space between buttons */
  font-size: 1vw;
}

.number-button {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000; /* Border color */
  font-size: 1.3em; /* Adjust font size as needed */
  font-weight: 500;
  color: #0f0718; /* Text color */
  background-color: #fff; /* Background color */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
}

.restaurantInput .ant-input::-webkit-input-placeholder {
  color: #929098 !important;
  font-size: 1em !important;
  margin-left: -1.5vw;
  font-weight: 500;
  font-family: Inter;
}

.restaurantInput .ant-input:-ms-input-placeholder {
  color: #929098 !important;
  font-size: 1em !important;
  margin-left: -1.5vw;
  font-weight: 500;
  font-family: Inter;
}

.restaurantInput .ant-input::placeholder {
  color: #929098 !important;
  font-size: 1em !important;
  margin-left: -1.5vw;
  font-weight: 500;
  font-family: Inter;
}

.customer-login {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background-image: url(/static/media/kioskBGI.7027d30d.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1px none;
  height: 100vh;
  width: 100%;
}

.user-name {
  color: #fff;
  font-family: Inter;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  right: 3.2vw;
  top: 1.8vw;
  margin: 0;
}

.welcome-text {
  color: #fff;
  text-align: center;
  -webkit-font-feature-settings: "clig" off, "liga" off;
          font-feature-settings: "clig" off, "liga" off;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: Inter, sans-serif;
  font-size: 5.3vw;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  margin: 0;
}

.welcome-text-des {
  color: #929098;
  text-align: center;
  -webkit-font-feature-settings: "clig" off, "liga" off;
          font-feature-settings: "clig" off, "liga" off;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: Inter;
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 600;
  padding-top: 1vh;
}

.cl-mobile-icon {
  width: 18.5vw;
  min-height: 23vh;
  border-radius: 25px;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  font-family: Inter;
}

.cl-img {
  margin-bottom: 1vh;
  height: 7vw;
  width: 7vw;
  padding: 1.3vw;
  border-radius: 50%;
  border: 1px solid #fff;
  cursor: pointer;
}

.cl-or-txt {
  width: 23vw;
  min-height: 23vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  font-family: Inter;
  padding-bottom: 5vh;
  font-size: 2.1vw;
  opacity: 0.7;
  word-wrap: break-word;
}

.cl-email-icon {
  width: 18.5vw;
  min-height: 23vh;
  border-radius: 25px;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  font-family: Inter;
}

.left-bottom-images {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
}

.bottom-img {
  padding: 1vw 1vw 3vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.spin-container {
  position: relative;
}

.custom-box {
  width: 92%;
  height: 11.8vh;
  background-color: #eec302;
  text-align: center;
  margin-top: 1.5vh;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.custom-box:hover {
  /* background-color: #EEC302 !important;  */
  color: #fff !important;
  cursor: pointer !important;
}

/***********Mobile Component************/
.mc-main-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 93vh;
}

.mc-left-div {
  position: relative;
  bottom: 2%;
  margin-top: -5%;
  /* left: 4%; */
  bottom: 3rem;
  display: flex;
  justify-content: center;
}

.mc-input-field {
  position: relative;
  width: 26rem;
  border: 1px solid #fff;
  border-radius: 7px;
  color: #fff;
  font-size: 1.6rem;
  height: 3.4rem;
  padding: 1.2rem;
  background: transparent !important;
  outline: none;
}

.login-input-field {
  position: relative;
  width: 100%;
  border: 1px solid #fff;
  border-radius: 7px;
  color: #fff;
  font-size: 1.6rem;
  height: 9vh;
  padding: 2vh;
  background: transparent !important;
}

.mc-input-lable {
  position: absolute;
  top: calc(50% - 1.5rem);
  font-size: 1.3rem;
  color: #a0a8b2;
  left: 10%;
  background-color: white;
  padding: 0 3%;
}

.mc-continue-btn {
  position: relative;
  background-color: #eec302;
  border-color: #eec302;
  width: 26.2rem;
  height: 3.7rem;
  border-radius: 7px;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
}

.mc-continue-btn:hover {
  background-color: #eec302 !important;
  color: #fff !important;
}

.mc-mobile-icon {
  height: 4vh;
  width: 3vw;
  position: relative;
  right: 51%;
}

.mc-kb-col {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 2%;
}

.mc-kb-div {
  height: 27.3rem;
  width: 23.6rem;
  position: relative;
  top: 19vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

.mc-cl-img {
  margin-bottom: 1vh;
  height: 7vw;
  width: 7vw;
  padding: 1.3vw;
  border-radius: 50%;
  border: 1px solid #fff;
  position: relative;
  bottom: -4vh;
}

/***********Email Component************/
.email-icon-bg {
  height: 12.6vh;
  width: 9.3vw;
  position: relative;
  left: 50%; /* Adjusted to center horizontally */
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); /* Added to center horizontally */
}

.email-icon {
  margin-bottom: 1vh;
  height: 7vw;
  width: 7vw;
  padding: 1.3vw;
  border-radius: 50%;
  border: 1px solid #fff;
  position: relative;
  top: -2vh;
  /* bottom: -1vh; */
}

.email-input {
  position: relative;
  width: 25rem;
  border: 1px solid #0580bd;
  border-radius: 5px;
  color: black;
  font-size: 2rem;
  height: 5rem;
  padding: 1.2rem;
  top: 15%;
}

.email-lable {
  position: absolute;
  top: 60%;
  font-size: 1.3rem;
  color: #a0a8b2;
  left: 3%;
  background-color: white;
  padding: 0 2%;
}

.email-keyboard-div {
  height: 18rem;
  width: 100%;
  position: relative;
  top: 27%;
}

.email-kb-div {
  height: 40vh;
  padding: 1.5vh 11vw 1.5vh 11vw;
  width: 100%;
  /* top: 2vh; */
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

.email-continue-btn {
  background-color: #eec302;
  border-color: #eec302;
  width: 26.2rem;
  height: 3.7rem;
  border-radius: 7px;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  margin-top: 0.5%;
}

.email-continue-btn:hover {
  background-color: #eec302 !important;
  color: #fff !important;
}

/***********Scan Component************/

.scan-cl-img {
  height: 26vw;
  width: 26vw;
  border-radius: 50%;
  border: 1px solid #fff;
  position: relative;
  background-color: #fff;
}

/* Payment Secetion */

.proceed-to-pay-btn {
  height: 11vh;
  background-color: #eec302;
  width: 21.4vw;
  color: #fff;
  border-radius: 7px;
  font-size: 1.8vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
}

.proceed-to-pay-btn:hover {
  background-color: #eec302 !important;
  color: #fff !important;
}

.mobile.simple-keyboard.hg-layout-default .hg-button {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    align-items: center;
    background: #FFF;
    border-radius: 5px;
    box-shadow: 0 0 3px -1px rgb(0 0 0 / 30%);
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-size: 2.4rem;
    border-radius: 1.2rem;
    height: 7rem;
    justify-content: center;
    padding: 0px;
    margin: 0.3rem;
    font-weight: 500;
}

.mobile.simple-keyboard.hg-layout-default .hg-button.hg-bksp {
  width: 1.2vw;
  background-image: url(/static/media/kiosk-bksp.10f9f4d4.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40% 40%;
  background-color: #626367;
}

.mobile.simple-keyboard.hg-layout-default .hg-button.hg-bksp >span{
  visibility: hidden;
  width: 1.2vw;
}
.mobile.hg-theme-default .hg-row:nth-child(2){
  padding: 0 !important;
}

.mobile.hg-theme-default .hg-row .hg-button.hg-bksp{
  width: 1.2vw;
  /* background-image: url("../../../../assets/images/kiosk-bksp.svg"); */
  background-image: url(/static/media/backspace_1.63fc938f.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40% 40%;
  background-color: #626367;
}

.mobile.hg-theme-default .hg-row .hg-button.hg-bksp > span{
  visibility: hidden;
  width: 1.2vw;
}

.mobile.hg-theme-default {
    background-color: transparent !important;
    border-radius: 5px;
    box-sizing: border-box;
    font-family: HelveticaNeue-Light,Helvetica Neue Light,Helvetica Neue,Helvetica,Arial,Lucida Grande,sans-serif;
    overflow: hidden;
    padding: 5px;
    touch-action: manipulation;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
    width: 100%;
}
.simple-keyboard.hg-layout-default .hg-button.hg-bksp {
    width: 2vw
  }



.email.hg-theme-default {
    background-color: transparent !important;
}
.email.simple-keyboard.hg-layout-default .hg-button {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    align-items: center;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-size: 1.7rem;
    border-radius: 18px;
    height: 8vh;
    justify-content: center;
    padding: 0px;
    margin: 0.5vh;
}
.email.simple-keyboard.hg-theme-default .hg-row:nth-child(2) {
    margin-left: 4vw;
    margin-right: 4vw;
}
.email.simple-keyboard.hg-layout-default .hg-button.hg-yellow {
    width: 13vw
  }
.email.hg-theme-default .hg-row:nth-child(2){
    padding: 0 !important;
}

.login.hg-theme-default .hg-button.hg-space {
    color: black;
    width: 17vw
}

.simple-keyboard.hg-layout-default .hg-button.hg-mail {
    width: 13vw
  }
.simple-keyboard.hg-layout-shift .hg-button.hg-mail {
    width: 13vw
}
.email.simple-keyboard.hg-theme-default .hg-row:nth-child(4) {
    margin-left: 3vw;
    margin-right: 3vw;
}

.hg-theme-default .hg-button{
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    align-items: center;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-size: 1.7rem;
    border-radius: 0.9rem;
    height: 7.6vh;
    justify-content: center;
    padding: 0px;
    margin: 0.2rem;
}


